import React, { useState } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { DashboardApi } from "../../../apis/DashboardApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { login } from "../../reducer/loginSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Oval } from 'react-loader-spinner'
import { AxiosResponse } from 'axios';
import COUNTRYDATA from "../../assets/country-list.json";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { AuthApis } from '../../../apis/authApis';

function KYC() {
  let [screen, setScreen] = React.useState<any>(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);

  let [userDatas, setUserDatas] = React.useState({
    date_of_birth: "",
    first_name:"",
    last_name:"",
    place_of_birth: "null",
    state_of_origin: "", nationality: "",
    nin_number: "",  passport_number: "",
    occupation: "",
    employment_status: "", level_of_education: "",
    address: "", utility_type: "",
    profile_picture: "", nin_picture: '',
    utility_bill_picture: ''
  });


  const optionCountries: any = [];
  const optionState: any = [];
  COUNTRYDATA.map((item) => {
    optionCountries.push({ label: item.name, value: item.iso2 });
  });

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      height: 46,
      minHeight: 46,
      background: "#FFFFFF",
      color: "#1F2337",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#1F2337", // Custom colour
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    menu: (base: any) => ({
      ...base,
      background: "white",
      color: "#1F2337",
    }),
    input: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
    singleValue: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
  };

  function customTheme(theme: any) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#1DB459",
        primary: "#00B07B",
      },
    };
  }

  const handleChange = (e: any) => {
    setUserDatas({ ...userDatas, [e.target.name]: e.target.value });
  }

  


  const [countryValue, setCountryValue] = useState([]);
  const [stateOptions, setStateOptions] = useState<any>([]);

  const [selectedPics, setSelectedPics] = React.useState<any>('No selected file');
  const changeHandler = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == 'profile') {
          target.value = ''
          toast.warn('File too large');
        }
      }
      if (size <= 3) {
        if (targetName == 'profile') {
          setSelectedPics(event.target.files[0]);
        }
      }
    }
  };


  const [selectedPics2, setSelectedPics2] = React.useState<any>('No selected file');
  const changeHandler2 = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == 'nin') {
          target.value = ''
          toast.warn('File too large');
        }
      }
      if (size <= 3) {
        if (targetName == 'nin') {
          setSelectedPics2(event.target.files[0]);
        }
      }
    }
  };


  const [selectedPics3, setSelectedPics3] = React.useState<any>('No selected file');
  const changeHandler3 = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == 'utility_picture') {
          target.value = ''
          toast.warn('File too large');
        }
      }
      if (size <= 3) {
        if (targetName == 'utility_picture') {
          setSelectedPics3(event.target.files[0]);
        }
      }
    }
  };


  const [kycStatus, setKycStatus] = React.useState<any>([]);

  React.useEffect(() => {
    DashboardApi.verifyKyc().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setSelectedPics2(response?.data?.data?.nin_picture)
          setUserDatas(response?.data?.data)
          setKycStatus(response?.data?.data?.status)
          if (response?.data?.data?.status == 'approved') {
            navigate('/new-dashboard');
          }else{
            navigate('/kyc');
          }
        
        }
      }
    ).catch(function (error) {
      // handle error

    })


  }, []);



  const handleSelectChange = (name: any, option: any) => {
    setUserDatas((prevData: any) => ({ ...prevData, [name]: option.value }));

    if (name === "nationality") {
      const selectedCountry = COUNTRYDATA.find(country => country.iso2 === option.value);
      const states = selectedCountry ? selectedCountry.states.map(state => ({ label: state.name, value: state.name })) : [];
      setStateOptions(states);
    }
  };

  const [loader, setLoader] = React.useState<any>(false);

  const UpdateUser = React.useCallback(
    (e: any) => {
      e.preventDefault();
      setLoader(true)
      const userData = new FormData();
      userData.append('id', userLoginData?.data?.user_data?.id);
      userData.append('date_of_birth', userDatas?.date_of_birth);
      userData.append('place_of_birth', 'null');
      userData.append('state_of_origin', userDatas?.state_of_origin);
      userData.append('nationality', userDatas?.nationality);
      userData.append('nin_number', userDatas?.nin_number);
      userData.append('passport_number', userDatas?.passport_number);
      userData.append('occupation', userDatas?.occupation);
      userData.append('employment_status', userDatas?.employment_status);
      userData.append('level_of_education', userDatas?.level_of_education);
      userData.append('address', userDatas?.address);
      userData.append('utility_type', userDatas?.utility_type);
      userData.append('profile_picture', selectedPics);
      userData.append('nin_picture', selectedPics2);
      userData.append('utility_bill_picture', selectedPics3);


      DashboardApi.uploadKyc(userData).then(
        (response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            AuthApis.logout('').then(
              (response) => {
                if (response?.data) {
                  dispatch(login([]))
                  navigate('/sign-in');
        
                }
              }
            )
            // window.location.reload()
            console?.log(response?.data)
            // toast.success('Information under review, we will let you know once we approve you.');
            setLoader(false)
            // console?.log(response?.data?.data)
            // navigate('/wallet');
            // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
          }
        }
      ).catch((err) => {
        if (err?.response?.status == 422) {
          toast.error('Form not rightly filled. Kindly Cross check.');
          setLoader(false)
        } else if(err?.response?.status == 302){
          // console?.log(err?.response?.data?.message)
          toast.error(err?.response?.data?.message);
          setLoader(false)
        }


      }).finally(() => {

      });
    },
    [selectedPics, selectedPics2, selectedPics3, userDatas, loader]
  );

  // console.log(userDatas)

  // Function to get the max date for the date input (18 years ago from today)
  const getMaxDate = () => {
    const today = new Date();
    const year = today.getFullYear() - 16;
    const month = today.getMonth() + 1; // getMonth() is zero-based
    const day = today.getDate();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
          <div className='pt-10 flex space-x-3'>
            {/* <div className=' place-content-center mt-8 cursor-pointer' onClick={() => setScreen(screen == 1 ? screen : screen -= 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#6e7093" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" /></svg>
            </div> */}
            <div>
              <h1 className='font-bold md:text-[32px] text-[25px]'>KYC</h1>
              <hr className='w-[266px] h-1 bg-[#00B07B]' />
            </div>

          </div>

          {kycStatus == 'inReview' ?
            <div className=''>
              <h1 className='text-[32px] font-semibold text-[#0A0A0C] text-center'>Verification Under Review</h1>
              <p className='text-[16px] text-[#414143] text-center mt-2'>
                Thank you for submitting your verification documents. Our team is currently reviewing your information to ensure compliance with our verification requirements. This process typically takes 24 hours.
              </p>
              <div className='flex justify-center mt-5'>
                <img src="/images/Dashbord/kyc.png" alt="logo" />
              </div>

            </div>


            :
            <form onSubmit={UpdateUser}>
              <div className={(screen == 1 ? '' : 'hidden')}>
                <div className='flex flex-col items-center justify-center mx-auto pt-10'>
                  <div className='pt-8'>
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto  py-10 pt-0">
                        <div className="flex flex-wrap">
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="full_name"
                              >
                                Full Name
                              </label>
                              <input
                                type="text"
                                disabled
                                onChange={handleChange}
                                name="full_name"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder={userDatas?.first_name + ' ' + userDatas?.last_name}

                              />
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="date_of_birth"
                              >
                                Date of Birth
                              </label>
                              <input
                                type="date"
                                onChange={handleChange}
                                defaultValue={userDatas?.date_of_birth}
                                required
                                max={getMaxDate()}
                                name="date_of_birth"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder="DD-MM-YYYY"

                              />
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label htmlFor="level_of_education" className="text-[#000] text-sm font-medium mb-2 ">Highest Level of Education Attained</label>
                              <select required id="level_of_education" name="level_of_education" defaultValue={userDatas?.level_of_education}
                                onChange={handleChange}
                                className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                <option selected>{userDatas?.level_of_education}</option>
                                <option value="high school">High school</option>
                                <option value="college of education">College of education</option>
                                <option value="university">University</option>
                                <option value="masters/phd">Masters/PHD</option>
                                <option value="others">others</option>
                              </select>
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label htmlFor="employment_status" className="text-[#000] text-sm font-medium mb-2 ">Employment Status</label>
                              <select required id="employment_status" name="employment_status" 
                               defaultValue={userDatas?.employment_status} onChange={handleChange}
                                className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                <option selected>{userDatas?.employment_status}</option>
                                <option value="student">Student</option>
                                <option value="employed">Employed</option>
                                <option value="unemployed">Unemployed</option>
                                <option value="Entrepreneur/Self employed">Entrepreneur/Self Employed</option>
                                <option value="parent">Parent</option>
                              </select>
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="occupation"
                              >
                                Occupation
                              </label>
                              <input
                                type="text"
                                onChange={handleChange}
                                defaultValue={userDatas?.occupation}
                                required
                                name="occupation"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              />
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="nationality"
                              >
                                Nationality
                              </label>
                              <Select
                                name="nationality"
                                options={optionCountries}
                                isSearchable
                                theme={customTheme}
                                styles={customStyles}
                                required ={userDatas?.nationality !==''?false:true}
                                defaultValue={userDatas?.nationality}
                                // defaultInputValue={userDatas?.nationality}
                                // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                                placeholder={userDatas?.nationality?userDatas?.nationality:'Select Country'}
                                noOptionsMessage={() => "Country not found"}
                                onChange={(option) => handleSelectChange('nationality', option)}
                              />
                              {/* <input
                                type="text"
                                required
                                onChange={handleChange}
                                name="nationality"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              /> */}
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="nin_number"
                              >
                                National Identification Number (For Nigerian citizens only)
                              </label>
                              <input
                                type="number"
                                
                                defaultValue={userDatas?.nin_number}
                                onChange={handleChange}
                                name="nin_number"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              />
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="passport_number"
                              >
                                International Pasport Number (For Non Nigerian citizens)
                              </label>
                              <input
                                type="number"
                               
                                defaultValue={userDatas?.passport_number}
                                onChange={handleChange}
                                name="passport_number"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              />
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="nationality"
                              >
                                Country
                              </label>
                              <Select
                                name="nationality"
                                options={optionCountries}
                                isSearchable
                                theme={customTheme}
                                styles={customStyles}
                                required ={userDatas?.nationality !==''?false:true}
                                defaultValue={userDatas?.nationality}
                                // defaultInputValue={userDatas?.nationality}
                                // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                                placeholder={userDatas?.nationality?userDatas?.nationality:'Select Country'}
                                noOptionsMessage={() => "Country not found"}
                                onChange={(option) => handleSelectChange('nationality', option)}
                              />
                              {/* <input
                                type="text"
                                required
                                onChange={handleChange}
                                name="nationality"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              /> */}
                            </div>
                          </div>
                          {/* <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="place_of_birth"
                              >
                                Place of Birth
                              </label>
                              <input
                                type="text"
                                onChange={handleChange}
                                required
                                name="place_of_birth"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              />
                            </div>
                          </div> */}
                          
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="state_of_origin"
                              >
                                State of Origin
                              </label>
                              <CreatableSelect
                                name="state_of_origin"
                                options={stateOptions}
                                isSearchable
                                isClearable
                                required ={userDatas?.state_of_origin !==''?false:true}
                                defaultValue={userDatas?.state_of_origin}
                                theme={customTheme}
                                styles={customStyles}
                                placeholder={userDatas?.state_of_origin?userDatas?.state_of_origin:'Select State'}
                                noOptionsMessage={() => "select a Country"}
                                onChange={(option) => handleSelectChange('state_of_origin', option)}
                              />
                              {/* <input
                                type="text"
                                required
                                onChange={handleChange}
                                name="state_of_origin"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              /> */}
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="full_name"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                disabled
                                onChange={handleChange}
                                name="city"
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder={userDatas?.first_name + ' ' + userDatas?.last_name}

                              />
                            </div>
                          </div>
                          <div className='w-full lg:w-6/12 px-4'>
                            <div className="relative w-full mb-6">
                              <label
                                className="block text-[#000] text-sm font-medium mb-2"
                                htmlFor="address"
                              >
                                Address
                              </label>
                              <input
                                required
                                type="text"
                                onChange={handleChange}
                                name="address"
                                defaultValue={userDatas?.address}
                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                placeholder=""

                              />
                            </div>
                          </div>

                          <div className='w-full w-12/12'>
                            <div className='flex flex-col items-center justify-center mx-auto pt-10'>
                              <h3 className='text-[24px] font-semibold'>Upload a proof of your identity </h3>
                              <h5 className='mt-1 text-[14px] font-medium'>AfriPay requires a valid government issued ID (National ID)</h5>
                            </div>
                            <div className='pt-8'>

                              <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                <div className="flex-auto  py-10 pt-0">
                                  <div className="flex flex-wrap">

                                    <div className='w-full lg:w-12/12 px-4 mb-6'>
                                      <label
                                        className="block text-[#000] text-sm font-medium mb-2"
                                        htmlFor="upload_nin"
                                      >
                                        Upload Means Of Identification
                                      </label>
                                      <div className='border-2 border-gray-300 border-dashed rounded-md pt-10 pb-8 h-[160px] text-center'>
                                        <label htmlFor='upload_nin'>
                                          <h3 className='text-[12px] font-medium'>{selectedPics2 !== 'No selected file' ? selectedPics2?.name : 'e.g, Driver\'s Licence,Nin Slip,passport,e.t.c'}   </h3>
                                          <h3 className='text-[14px] pt-4 text-gray-400 font-medium'>{selectedPics2 !== 'No selected file' ? '' : 'Not more than 3MB'}   </h3>
                                          <div className='mt-5'>
                                            <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">Choose a File</span>
                                          </div>
                                          <input id="upload_nin" type="file" accept="image/png, image/jpeg" name='nin' onChange={changeHandler2} className="hidden" />
                                        </label>
                                      </div>
                                    </div>



                                    <div

                                      className="w-full lg:w-12/12 px-4 flex">

                                      <button
                                        type='submit'
                                        disabled={loader}
                                        className=
                                        "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                      >

                                        <span className='flex justify-center'>
                                          <span> Submit</span>
                                          <span>
                                            <Oval
                                              visible={loader}
                                              height="20"
                                              width="80"
                                              color="#00B07B"
                                              secondaryColor="#E6F1FC"
                                              ariaLabel="oval-loading"
                                              wrapperStyle={{}}
                                              wrapperClass=""
                                            />
                                          </span>

                                        </span>



                                      </button>


                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>



                          {/* <div
                        onClick={() => setScreen(2)}
                        className="w-full lg:w-6/12 px-4">

                        <button
                          type='button'
                          className=
                          "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                        >Continue</button>

                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={(screen == 2 ? '' : 'hidden')}>


              </div>

            </form>
          }

        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default KYC