import React, { useState, useEffect } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import HeaderTitle from "../../ReuseableComponent/HeaderTitle";
import UserNavbar from "../../Navbars/UserNavbar";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
// import { FaRegCircleCheck } from "react-icons/fa6";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  registerables,
} from "chart.js";


import { Doughnut, getElementsAtEvent } from "react-chartjs-2";
import COUNTRYDATA from "../../assets/country-list.json";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import { DashboardApi } from "../../../apis/DashboardApi";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { AxiosResponse } from "axios";
import { PaymentApis } from "../../../apis/paymentApis";
import { TbCopy } from "react-icons/tb";
import { MdOutlineMail } from "react-icons/md";
import { TbBrandWhatsapp } from "react-icons/tb";

const LoanGoldEligibility = () => {
  const [dataArray, setDataArray] = useState<number[]>([25, 75]);
  const [dataArrayOne, setDataArrayOne] = useState<number[]>([50, 50]);
  const [dataArrayTwo, setDataArrayTwo] = useState<number[]>([75, 25]);
  const [stateOptions, setStateOptions] = useState<any>([]);

  ChartJs.register(...registerables, Tooltip, Title, ArcElement, Legend);

  // Function to get the max date for the date input (18 years ago from today)
  const getMaxDate = () => {
    const today = new Date();
    const year = today.getFullYear() - 16;
    const month = today.getMonth() + 1; // getMonth() is zero-based
    const day = today.getDate();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };

  const dispatch = useDispatch();
  const userLoginData = useSelector((state: any) => state.data.login.value);
  // console.log(userLoginData)
  // Custom plugin to draw text in the center
  const centerTextPlugin = {
    id: "centerTextPlugin",
    beforeDraw: function (chart: any) {
      const { width, height, ctx, data } = chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${(data.datasets[0].data[0] /
          data.datasets[0].data.reduce((a: any, b: any) => a + b, 0)) *
        100
        }%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const data = {
    datasets: [
      {
        data: dataArray,
        backgroundColor: ["#00B07B", "#D9D9D9"],
        cutout: "70%", // Adjust this value to make the doughnut thinner
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Send", "Receive"],
  };



  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const centerTextPluginOne = {
    id: "centerTextPluginOne",
    beforeDraw: function (chart: any) {
      const { width, height, ctx, data } = chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${(data.datasets[0].data[0] /
          data.datasets[0].data.reduce((a: any, b: any) => a + b, 0)) *
        100
        }%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const dataOne = {
    datasets: [
      {
        data: dataArrayOne,
        backgroundColor: ["#00B07B", "#D9D9D9"],
        cutout: "70%", // Adjust this value to make the doughnut thinner
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Send", "Receive"],
  };

  const optionsOne = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const centerTextPluginTwo = {
    id: "centerTextPluginTwo",
    beforeDraw: function (chart: any) {
      const { width, height, ctx, data } = chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${(data.datasets[0].data[0] /
          data.datasets[0].data.reduce((a: any, b: any) => a + b, 0)) *
        100
        }%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const dataTwo = {
    datasets: [
      {
        data: dataArrayTwo,
        backgroundColor: ["#00B07B", "#D9D9D9"],
        cutout: "70%", // Adjust this value to make the doughnut thinner
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Send", "Receive"],
  };

  const optionsTwo = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };



  const optionCountries: any = [];
  const optionState: any = [];
  COUNTRYDATA.map((item) => {
    optionCountries.push({ label: item.name, value: item.name });
  });

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      height: 46,
      minHeight: 46,
      background: "#FFFFFF",
      color: "#1F2337",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#1F2337", // Custom colour
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    menu: (base: any) => ({
      ...base,
      background: "white",
      color: "#1F2337",
    }),
    input: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
    singleValue: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
  };

  function customTheme(theme: any) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#1DB459",
        primary: "#00B07B",
      },
    };
  }

  const [copiedField, setCopiedField] = useState<string | null>(null);
  const copyToClipboard = (text: string, field: string) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  const [open, setOpen] = useState(false);
  const onOpenModal = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const [successOpen, setSuccessOpen] = useState(false);
  const onOpenSuccessModal = () => setSuccessOpen(true);
  const onCloseSuccessModal = () => setSuccessOpen(false);
  const [selectedOption, setSelectedOption] = useState("");
  let [showScreen, setShowScreen] = React.useState<any>(21);
  const [countryValue, setCountryValue] = useState([]);

  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes in seconds

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const [openPayment, setOpenPayment] = useState(false);
  const onOpenPaymentModal = () => {
    // e.preventDefault();
    setOpenPayment(true);
    onCloseModal()
  };
  const onClosePaymentModal = () => {
    setOpenPayment(false);
    onOpenPaymentProofModal();
  };
  const [openPaymentProof, setOpenPaymentProof] = useState(false);
  const onOpenPaymentProofModal = () => {
    // e.preventDefault();
    setOpenPaymentProof(true);
  };
  const onClosePaymentProofModal = () => {
    setOpenPaymentProof(false);
    setShowScreen(21)

    // window.location.reload()
  };
  // const navigate = useNavigate();

  // const handleSelectChange = (name:any, option:any) => {
  //   setUserdata((prevData:any) => ({ ...prevData, [name]: option.value }));
  // };
  const [selectedPics2, setSelectedPics2] =
    React.useState<any>("No selected file");
  const changeHandler2 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "front_nin_card") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "front_nin_card") {
          setSelectedPics2(event.target.files[0]);
        }
      }
    }
  };

  const [selectedPics3, setSelectedPics3] =
    React.useState<any>("No selected file");
  const changeHandler3 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "back_nin_card") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "back_nin_card") {
          setSelectedPics3(event.target.files[0]);
        }
      }
    }
  };

  const [selectedPics4, setSelectedPics4] =
    React.useState<any>("No selected file");
  const changeHandler4 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "front_id_card") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "front_id_card") {
          setSelectedPics4(event.target.files[0]);
        }
      }
    }
  };

  const [selectedPics5, setSelectedPics5] =
    React.useState<any>("No selected file");
  const changeHandler5 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "back_id_card") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "back_id_card") {
          setSelectedPics5(event.target.files[0]);
        }
      }
    }
  };

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value);
    if (
      e.target.value === "individual credit report -#5000" ||
      e.target.value === "annual credit report"
    ) {
      setShowScreen(21); // Change to the next screen
    } else if (e.target.value === "business credit report- #9650") {
      setShowScreen(22);
    } else {
      setShowScreen(21);
    }
    // setUserdata((prevData:any) => ({
    //   ...prevData,
    //   credit_type: e.target.value,
    // }));
  };
  // const [userData, setUserdata] = React.useState<any>([]);
  // console.log(userData)

  const [singleCredit, setSingleCredit] = React.useState<any>([]);
  const loanCredit = sessionStorage.getItem("loanCredit");
  const loanCreditObj = loanCredit ? JSON.parse(loanCredit) : null;


  const [userData, setUserdata] = React.useState<any>({
    reg_type: "EduGold",
    nationality: loanCreditObj?.nationality ? loanCreditObj?.nationality : "No data available",
    admission_letter: loanCreditObj?.admission_letter ? loanCreditObj?.admission_letter : "No data available",
    country_of_residence: loanCreditObj?.country_of_residence ? loanCreditObj?.country_of_residence : "No data available",
    program_type: loanCreditObj?.program_type ? loanCreditObj?.program_type : "No data available",
    stem_course: loanCreditObj?.stem_course ? loanCreditObj?.stem_course : "No data available",
    course_of_study: loanCreditObj?.course_of_study ? loanCreditObj?.course_of_study : "No data available",
    country_of_School_accepted_to: loanCreditObj?.country_of_School_accepted_to ? loanCreditObj?.country_of_School_accepted_to : "No data available",
    school_accepted_to: loanCreditObj?.school_accepted_to ? loanCreditObj?.school_accepted_to : "No data available",
    first_name: userLoginData?.data?.user_data?.first_name,
    last_name: userLoginData?.data?.user_data?.last_name,
    other_name: "",
    email: userLoginData?.data?.user_data?.email,
    gender: singleCredit?.gender ? singleCredit?.gender : "",
    dob: singleCredit?.dob ? singleCredit?.dob : "",
    mobile: singleCredit?.mobile ? singleCredit?.mobile : "",
    phone: singleCredit?.phone ? singleCredit?.phone : "",
    country: singleCredit?.country ? singleCredit?.country : "",
    state: singleCredit?.state ? singleCredit?.state : "",
    city: singleCredit?.city ? singleCredit?.city : "",
    street_address: singleCredit?.street_address
      ? singleCredit?.street_address
      : "",
    bvn: singleCredit?.bvn ? singleCredit?.bvn : "",
    identity_type: singleCredit?.identity_type
      ? singleCredit?.identity_type
      : "",
    front_id_card: selectedPics4,
    back_id_card: selectedPics5,
    // front_nin_card: "",
    // back_nin_card: "",
    previous_address: singleCredit?.previous_address
      ? singleCredit?.previous_address
      : "",
    marital_status: singleCredit?.marital_status
      ? singleCredit?.marital_status
      : "",
    employer_name: singleCredit?.name ? singleCredit?.employer_name : "",
    employer_address: singleCredit?.employer_address
      ? singleCredit?.employer_address
      : "",
    // nin_number: "",
    // id_card_number: "",
    // front_id_card: "",
    // back_id_card: "",
  });

  // console.log(userData)

  React.useEffect(() => {
    DashboardApi.getSingleCreditEligibity(userLoginData?.data?.user_data?.id)
      .then((response: AxiosResponse<any>) => {
        // console?.lo
        // if (response?.data.data?.admission_letter === "yes") {
        setSingleCredit(response?.data?.data);
        setUserdata(response?.data?.data)
        // }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);
  const [loader, setLoader] = React.useState<any>(false);
  // console.log(userData);

  const next = () => {
    setShowScreen(3);
  };

  // Retrieve the item from sessionStorage


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const [transactionList, setTransactionList] = React.useState<any>([]);

  React.useEffect(() => {
    DashboardApi.getDashboardData()
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setTransactionList(response?.data?.data);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  const handleSelectChange = (name: any, option: any) => {
    setUserdata((prevData: any) => ({ ...prevData, [name]: option.value }));

    if (name === "country") {
      const selectedCountry = COUNTRYDATA.find(
        (country) => country.name === option.value
      );
      const states = selectedCountry
        ? selectedCountry.states.map((state) => ({
          label: state.name,
          value: state.name,
        }))
        : [];
      setStateOptions(states);
    }
  };
  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      setLoader(true);
      const userDatas = new FormData();
      userDatas.append(
        "first_name",
        userLoginData?.data?.user_data?.first_name
      );
      userDatas.append("last_name", userLoginData?.data?.user_data?.last_name);
      userDatas.append(
        "other_name",
        userData?.other_name ? userData?.other_name : singleCredit?.other_name
      );
      userDatas.append("email", userLoginData?.data?.user_data?.email);
      userDatas.append(
        "gender",
        userData?.gender ? userData?.gender : singleCredit?.gender
      );
      userDatas.append(
        "dob",
        userData?.dob ? userData?.dob : singleCredit?.dob
      );
      userDatas.append(
        "mobile",
        userData?.mobile ? userData?.mobile : singleCredit?.mobile
      );
      userDatas.append(
        "phone",
        userData?.phone ? userData?.phone : singleCredit?.phone
      );
      userDatas.append(
        "country",
        userData?.country ? userData?.country : singleCredit?.country
      );
      userDatas.append(
        "state",
        userData?.state ? userData?.state : singleCredit?.state
      );
      userDatas.append(
        "city",
        userData?.city ? userData?.city : singleCredit?.city
      );
      userDatas.append(
        "street_address",
        userData?.street_address
          ? userData?.street_address
          : singleCredit?.street_address
      );
      userDatas.append(
        "bvn",
        userData?.bvn ? userData?.bvn : singleCredit?.bvn
      );
      userDatas.append(
        "identity_type",
        userData?.identity_type
          ? userData?.identity_type
          : singleCredit?.identity_type
      );
      // userDatas.append('utility_type', userDatas?.utility_type);
      userDatas.append("front_id_card", selectedPics4);
      userDatas.append("back_id_card", selectedPics5);
      // userDatas.append('utility_bill_picture', selectedPics3);
      userDatas.append(
        "previous_address",
        userData?.previous_address
          ? userData?.previous_address
          : singleCredit?.previous_address
      );
      userDatas.append(
        "marital_status",
        userData?.marital_status
          ? userData?.marital_status
          : singleCredit?.marital_status
      );
      userDatas.append(
        "employer_name",
        userData?.employer_name ? userData?.employer_name : singleCredit?.name
      );
      userDatas.append(
        "employer_address",
        userData?.employer_address
          ? userData?.employer_address
          : singleCredit?.employer_address
      );
      userDatas.append(
        "nationality",
        userData?.nationality
          ? userData?.nationality
          : singleCredit?.nationality
      );
      userDatas.append(
        "admission_letter",
        userData?.admission_letter
          ? userData?.admission_letter
          : singleCredit?.admission_letter
      );
      userDatas.append(
        "country_of_residence",
        userData?.country_of_residence
          ? userData?.country_of_residence
          : singleCredit?.country_of_residence
      );
      userDatas.append(
        "program_type",
        userData?.program_type
          ? userData?.program_type
          : singleCredit?.program_type
      );
      userDatas.append(
        "stem_course",
        userData?.stem_course
          ? userData?.stem_course
          : singleCredit?.stem_course
      );
      userDatas.append(
        "course_of_study",
        userData?.course_of_study
          ? userData?.course_of_study
          : singleCredit?.course_of_study
      );
      userDatas.append(
        "country_of_School_accepted_to",
        userData?.country_of_School_accepted_to
          ? userData?.country_of_School_accepted_to
          : singleCredit?.country_of_School_accepted_to
      );
      userDatas.append(
        "school_accepted_to",
        userData?.school_accepted_to
          ? userData?.school_accepted_to
          : singleCredit?.school_accepted_to
      );
      userDatas.append("reg_type", 'EduGold');
      // userDatas.append('country_of_residence', userData?.country_of_residence ? userData?.country_of_residence : singleCredit?.country_of_residence);

      DashboardApi.createCreditEligibility(userDatas)
        .then((response: any) => {
          if (response?.data) {
            setLoader(false);
            onOpenModal(e);
            toast.success("Your Credit Eligibility form is successful");
            console?.log(response?.data?.data);
            // navigate('/wallet');
            // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
          }
        })
        .catch((err) => {
          if (err?.response?.status == 422) {
            toast.error("Form not rightly filled. Kindly Cross check.");
            setLoader(false);
          } else {
            toast.error("Some error occured.Please try again");
            setLoader(false);
          }
        })
        .finally(() => { });
    },
    [selectedPics4, selectedPics5, userData, loader]
  );

  // console.log(singleCredit)
  // const handleSubmit = (e: any) => {
  //   // navigate("/loans-gold-plan")
  //   e.preventDefault();
  //   onOpenModal(e);
  // };
  // const handleSubmit2 = (e: React.ChangeEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   onCloseModal();
  //   onOpenSuccessModal();
  // };

  const handleSubmit2 = React.useCallback((e: any) => {
    e.preventDefault();
    setLoader(true);

    const formData = new FormData();
    // formData.append('amount', amount)
    formData.append("amount", "5000"); // Hardcoded amount
    PaymentApis.creditHistoryPayment(formData)
      .then((response: any) => {
        if (response?.data) {
          setLoader(false);
          // console.log(response)
          window.location.replace(response?.data?.data?.data?.link);
          //                 onCloseModal();
          // onOpenSuccessModal();
        }
      })
      .catch(function (error) {
        setLoader(false);

        toast.error("An error occured");
      });
  }, []);
  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <HeaderTitle title="Loan" />

          <div className="flex justify-center">
            <div className="pt-3 pb-7">
              <h3 className="text-[#000000] text-center md:text-[30px] text-[20px] font-[600]">
                Credit Eligibility
              </h3>
              <h5 className="text-[#5F5D5D] text-center md:text-[18px] text-[14px] font-[500]">
                Check your credit score to apply for loan
              </h5>
            </div>
          </div>

          <div className="grid lg:grid-cols-12 gap-3">
            <div className="lg:col-span-8 bg-white pt-2 px-2 rounded-[10px]">
              <form onSubmit={handleSubmit}>


                <div className={showScreen === 21 ? "block " : "hidden"}>
                  <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Individual Credit Check
                    </h4>
                  </div>
                  <div className="grid md:grid-cols-2 gap-3">
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        First name
                      </label>
                      <input
                        type="text"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        // placeholder="Answer"
                        name="first_name"
                        defaultValue={
                          userLoginData?.data?.user_data?.first_name
                        }
                        onChange={handleChange}
                        required
                        disabled
                      />
                    </div>

                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        // placeholder="Answer"
                        name="last_name"
                        defaultValue={userLoginData?.data?.user_data?.last_name}
                        required
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Other name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      // placeholder="Answer"
                      name="other_name"
                      defaultValue={
                        userData?.other_name ? userData?.other_name : ""
                      }
                      onChange={handleChange}
                    //   disabled

                    />
                  </div>

                  <div className="grid md:grid-cols-2 gap-3">
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Email
                      </label>
                      <input
                        type="email"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        // placeholder="example@gmail.com"
                        name="email"
                        defaultValue={userLoginData?.data?.user_data?.email}
                        onChange={handleChange}
                        required
                      // disabled
                      />
                    </div>

                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Gender
                      </label>
                      <select
                        id="gender"
                        value={userData?.gender ? userData?.gender : ""}
                        name="gender"
                        required
                        onChange={handleChange}
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      >
                        <option selected></option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Date of birth
                    </label>
                    <input
                      type="date"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      // placeholder="Answer"
                      name="dob"
                      max={getMaxDate()}
                      defaultValue={userData?.dob}
                      onChange={handleChange}
                      //   disabled
                      required
                    />
                  </div>

                  <div className="grid md:grid-cols-2 gap-3">
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Mobile
                      </label>
                      <input
                        type="number"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder=""
                        name="mobile"
                        defaultValue={userData?.mobile}
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleChange}
                        //   disabled
                        required
                      />
                    </div>

                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Phone
                      </label>
                      <input
                        type="number"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        // placeholder="Answer"
                        defaultValue={userData?.phone}
                        name="phone"
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleChange}
                        //   disabled
                        required
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 gap-3">
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Country
                      </label>
                      <Select
                        name="country"
                        options={optionCountries}
                        isSearchable
                        theme={customTheme}
                        styles={customStyles}
                        defaultValue={userData?.country ? userData?.country : ""}
                        required
                        // defaultInputValue={props.customer!.country}
                        // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        placeholder="select country"
                        noOptionsMessage={() => "Country not found"}
                        onChange={(option: any) =>
                          handleSelectChange("country", option)
                        }
                      />
                    </div>

                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        State
                      </label>
                      <CreatableSelect
                        name="state"
                        options={stateOptions}
                        isSearchable
                        isClearable
                        required
                        theme={customTheme}
                        defaultValue={userData?.state ? userData?.state : ""}
                        styles={customStyles}
                        placeholder="select a state"
                        noOptionsMessage={() => "select a Country"}
                        onChange={(option: any) =>
                          handleSelectChange("state", option)
                        }
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 gap-3">
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        City
                      </label>
                      <input
                        type="text"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder=""
                        defaultValue={userData?.city}
                        name="city"
                        required
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleChange}
                      //   disabled
                      />
                    </div>

                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Street Address
                      </label>
                      <input
                        type="text"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder=""
                        defaultValue={userData?.street_address}
                        name="street_address"
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleChange}
                        //   disabled
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className={showScreen === 3 ? "block " : "hidden"}>
                  <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Individual Credit Check
                    </h4>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      BVN
                    </label>
                    <input
                      type="number"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder="Answer"
                      defaultValue={userData?.bvn}
                      name="bvn"
                      //   defaultValue={userDetails?.first_name}
                      onChange={handleChange}
                      //   disabled
                      required
                    />
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Select Identification Type
                    </label>
                    <select
                      id="identity_type"
                      value={userData?.identity_type}
                      name="identity_type"
                      required
                      onChange={handleChange}
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected></option>
                      <option value="drivers_license">Drivers License</option>
                      <option value="nin">NIN</option>
                      <option value="passport">Passport</option>
                      <option value="voters_card">Voters Card</option>
                    </select>
                  </div>

                  <div className="lg:flex gap-3 w-full">
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Front view of ID card
                      </label>
                      <div className="border-2 border-gray-300 border-dashed rounded-md pt-10 pb-8 h-[160px] text-center">
                        <label htmlFor="front_id_card">
                          <h3 className="text-[12px] font-medium">
                            {selectedPics4 !== "No selected file"
                              ? selectedPics4?.name
                              : "e.g, Driver's Licence,Nin Slip,passport,e.t.c"}{" "}
                          </h3>
                          <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                            {selectedPics4 !== "No selected file"
                              ? ""
                              : "Not more than 3MB"}{" "}
                          </h3>
                          <div className="mt-5">
                            <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                              Choose a File
                            </span>
                          </div>
                          <input
                            id="front_id_card"
                            type="file"
                            required
                            accept="image/png, image/jpeg"
                            name="front_id_card"
                            onChange={changeHandler4}
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Back view of Id card
                      </label>
                      <div className="border-2 border-gray-300 border-dashed rounded-md pt-10 pb-8 h-[160px] text-center">
                        <label htmlFor="back_id_card">
                          <h3 className="text-[12px] font-medium">
                            {selectedPics5 !== "No selected file"
                              ? selectedPics5?.name
                              : "e.g, Driver's Licence,Nin Slip,passport,e.t.c"}{" "}
                          </h3>
                          <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                            {selectedPics5 !== "No selected file"
                              ? ""
                              : "Not more than 3MB"}{" "}
                          </h3>
                          <div className="mt-5">
                            <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                              Choose a File
                            </span>
                          </div>
                          <input
                            id="back_id_card"
                            type="file"
                            required
                            accept="image/png, image/jpeg"
                            name="back_id_card"
                            onChange={changeHandler5}
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Address
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      defaultValue={userData?.previous_address}
                      placeholder=""
                      name="previous_address"
                      //   defaultValue={userDetails?.first_name}
                      onChange={handleChange}
                      //   disabled
                      required
                    />
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Your Marital Status
                    </label>
                    <select
                      id="marital_status"
                      value={userData?.marital_status}
                      name="marital_status"
                      required
                      onChange={handleChange}
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected></option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="separated">Separated</option>
                      <option value="others">Others</option>
                      {/* <option value="complicated">Complicated</option> */}
                    </select>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Your Employer's Name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder=""
                      required
                      defaultValue={userData?.employer_name}
                      name="employer_name"
                      //   defaultValue={userDetails?.first_name}
                      onChange={handleChange}
                    //   disabled
                    />
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Your Employer's Address
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder=""
                      required
                      defaultValue={userData?.employer_address}
                      name="employer_address"
                      onChange={handleChange}

                    />
                  </div>
                </div>
                {showScreen === 21 ? (
                  <div className="flex gap-4 justify-center mb-10 pt-7">
                    <NavLink
                      to="/loans-gold-plan"
                      // onClick={() => setShowScreen(1)}
                      className="text-[#00B07B] border px-[55px] border-[#00B07B] flex justify-center items-center py-2 rounded-[10px] "
                    >
                      Back
                    </NavLink>
                    <button
                      disabled={
                        userData?.gender === "" ||
                        userData?.dob === "" ||
                        userData?.mobile === "" ||
                        userData?.phone === "" ||
                        userData?.country === "" ||
                        userData?.state === "" ||
                        userData?.city === "" ||
                        userData?.street_address === ""
                      }
                      onClick={next}
                      className="disabled:bg-gray-600 text-white px-[45px] bg-[#00B07B] flex justify-center items-center py-2 rounded-[10px] "
                    >
                      Proceed
                    </button>
                  </div>
                ) : showScreen === 3 ? (
                  <div className="flex gap-4 justify-center mb-10 pt-7">
                    <button

                      onClick={() => setShowScreen(21)}
                      className="text-[#00B07B] border px-[55px] border-[#00B07B] flex justify-center items-center py-2 rounded-[10px] "
                    >
                      Back
                    </button>
                    <button
                      disabled={
                        loader ||
                        userData?.bvn === "" ||
                        userData?.identity_type === "" ||
                        userData?.front_id_card === "" ||
                        userData?.back_id_card === "" ||
                        userData?.previous_address === "" ||
                        userData?.marital_status === "" ||
                        userData?.employer_name === "" ||
                        userData?.employer_address === "" ||
                        userData?.front_id_card === "" ||
                        userData?.back_id_card === ""
                      }
                      onClick={handleSubmit}
                      className="disabled:bg-gray-600 text-white  bg-[#00B07B] px-[45px] flex justify-center items-center py-3 rounded-[10px]"
                    >
                      {loader ? <LoadingSpinner /> : "Submit"}
                    </button>
                  </div>
                ) : (
                  ""
                )}



              </form>
            </div>

            <div className="lg:col-span-4">
              <div className="border rounded-[10px] h-fit  w-full flex justify-center relative">
                <div>
                  <h4 className="text-[#000000] text-[20px] text-center pt-4 font-semibold">
                    EduGold Stage
                  </h4>
                  {showScreen === 1 ? (
                    <>
                      <div className="p-4  md:w-[180px] md:h-[180px]">
                        <Doughnut
                          options={options}
                          data={data}
                          plugins={[centerTextPlugin]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <h4>
                          On Progress{" "}
                          <span className="text-[#00B07B]">25%</span>
                        </h4>
                      </div>
                    </>
                  ) : showScreen === 21 ? (
                    <>
                      <div className="p-4  md:w-[180px] md:h-[180px]">
                        <Doughnut
                          options={optionsOne}
                          data={dataOne}
                          plugins={[centerTextPluginOne]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <h4>
                          On Progress{" "}
                          <span className="text-[#00B07B]">50%</span>
                        </h4>
                      </div>
                    </>
                  ) : showScreen === 3 ? (
                    <>
                      <div className="p-4  md:w-[180px] md:h-[180px]">
                        <Doughnut
                          options={optionsTwo}
                          data={dataTwo}
                          plugins={[centerTextPluginTwo]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <h4>
                          On Progress{" "}
                          <span className="text-[#00B07B]">75%</span>
                        </h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="p-4  md:w-[180px] md:h-[180px]">
                        <Doughnut
                          options={options}
                          data={data}
                          plugins={[centerTextPlugin]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <h4>
                          On Progress{" "}
                          <span className="text-[#00B07B]">25%</span>
                        </h4>
                      </div>
                    </>
                  )}

                  <div className="flex justify-center mt-3 mb-10">
                    <h4 className=" text-center max-w-[200px]">
                      Form to check credit eligibility
                    </h4>
                  </div>
                </div>
              </div>

              <div className="rounded-[10px] mt-8">
                <div className="bg-white rounded-t-[10px] py-2 flex justify-center">
                  <h4 className="text-[#000000] text-[20px]">
                    What to do next
                  </h4>
                </div>
                <div className="bg-[#00B07B] rounded-b-[10px] flex justify-center pt-3 pb-5">
                  <h4 className="text-white text-[18px] text-center max-w-[300px]">
                    Fill credit eligibility form
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={open} onClose={onCloseModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>
          <div className=" flex justify-center flex-col mt-5">
            <h4 className="tex-[#000000] text-[16px] flex justify-center font-[500]">
              Payment for Credibility eligibility test
            </h4>
            {/* <p className=" text-sm flex justify-center">Please, we recommend that you use the card option for payment.</p> */}
          </div>
          <div className=" md:w-[460px] body-font font-poppins">


            <div className="flex justify-center  pt-7 mb-2">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="w-5 h-5 accent-green-600 bg-[#D9D9D9] border-green-600 rounded"
              />
              <label
                htmlFor="default-checkbox"
                className="ml-2 text-[15px]  text-gray-900 "
              >
                <span className="text-red-700">NOTE</span> This payment is
                non-refundable regardless of whether you pass or fail the credit
                eligibility test.
              </label>

              
            </div>
            <div className="flex justify-center">
            <h5 className="text-[#6F6C90] pt-3 text-[17px] font-[400] ">
                  Those outside Nigeria are kindly requested to contact their local banks to obtain their Credit Report and send it to
                  <a href="mailto:technicalsupport@goafripay.com" className="text-[#00b07b]"> technicalsupport@goafripay.com</a>
                  
                  </h5>
            </div>
           

            <div className="flex justify-center mt-3">
              <span>
                <button
                  // onClick={(e: any) => handleSubmit2(e)}
                  onClick={onOpenPaymentModal}
                  disabled={!isChecked}
                  className={`text-white flex justify-center items-center py-3 px-4 rounded-[10px] w-full ${isChecked ? "bg-[#00B07B]" : "bg-gray-600 cursor-not-allowed"
                    }`}
                >
                  {loader ? <LoadingSpinner /> : "Pay NGN5,000"}
                </button>
              </span>

            </div>

          </div>
        </Modal>

        <Modal open={openPayment} onClose={onClosePaymentModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>

          <div className=" flex justify-start  mt-1">
            <h4 className="tex-[#000000] text-[16px]  font-[500]">
              Payment
            </h4>

          </div>
          <div className="md:w-[460px] body-font font-poppins">

            {/* Payment Amount */}
            <div className="bg-[#F7F7F7] rounded-[8px] pt-3 pb-1 text-center mb-5 mt-5">
              <p className="text-[14px] text-[#5A5A5A]">Payment</p>
              <p className="text-[28px] font-semibold text-[#00B07B]">₦5,000</p>
            </div>

            {/* local page */}
            <div >
              <div className=" mt-[20px] mb-8">
                <div className='border border-[#D6D9DC] rounded-[10px] px-2 py-2'>

                  {/* <div className='flex justify-between'>
                    <h3 className='font-semibold text-[20px] text-[000]'>Summary</h3>
                    <span className="text-sm text-center text-[#B00000] border border-[#B00000] rounded-[8px] pt-1 px-4">
                      Expire in {formatTime(timeLeft)}

                    </span>
                  </div> */}

                  <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>You will transfer</h4>
                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'>₦5,000 </h3>
                  </div>

                </div>
              </div>

              {/* Bank Details */}
              <div className="space-y-4 mb-8">
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Name</span>
                  <span className="font-medium text-[14px]">AFRIPRO PLATFORM NIG LTD</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Number</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">1026908037</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('1026908037', 'account')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'account' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>

                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Bank Name</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">United Bank of Africa (UBA)</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('United Bank of Africa (UBA)', 'bank')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'bank' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <button onClick={onClosePaymentModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                I have make payment
              </button>
            </div>



          </div>
        </Modal>

        <Modal open={openPaymentProof} onClose={onClosePaymentProofModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>
          <div className='md:w-[350px] w-[300px]'>
            <div className=" flex justify-start mt-7">
              <h4 className="tex-[#000000] md:text-[18px] text-[14px] font-bold">
                Proof of Payment
              </h4>

            </div>
            <div className='flex  mt-5'>
              <p className='text-[14px] text-[#898989] '>Kindly send your receipt to any of the following platform to confirm your payment </p>
            </div>

            <div className=' mt-5'>
              <div className='flex gap-2'>
                <MdOutlineMail size={20} />
                <a href="mailto:customersupport@goafripay.com" className=' underline underline-offset-2 text-[14px]'>customersupport@goafripay.com</a>
              </div>
              <div className='flex gap-2 mt-3'>
                <TbBrandWhatsapp size={20} />
                <NavLink to="https://api.whatsapp.com/send?phone=2348131470992" target='_blank'>
                  <span className=' underline underline-offset-2 text-[14px]'>+234 813 147 0992</span>
                </NavLink>
              </div>
            </div>
            <div>

              <div className='mt-5 flex justify-center'>
                <button onClick={onClosePaymentProofModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                  Okay
                </button>

              </div>
            </div>
          </div>

        </Modal>

        <Modal open={successOpen} onClose={onCloseSuccessModal} center>
          <div className="md:w-[500px]  body-font font-poppins">
            <div className="flex justify-center mt-9">
              <h4 className="text-center text-[30px] font-bold">
                Payment Successful
              </h4>
            </div>
            <div className="flex justify-center mt-2">
              <h4 className="text-center text-[20px] max-w-[450px]">
                Your payment for credit eligibility has been confirmedand is
                currently under review
                <br />
                Congratulation
              </h4>
            </div>
            <div className="flex justify-center">
              <IoIosCheckmarkCircleOutline className="w-[150px] h-[150px] text-[#00B07B]" />
            </div>
            <div className="flex justify-center  pt-7">
              <Link
                to="/loans-gold-success"
                className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
              >
                Proceed
              </Link>
            </div>
          </div>
        </Modal>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </div>
  );
};

export default LoanGoldEligibility;
