import React from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";

function PremiumLoanPackage() {
  return (
    <div>
        <Sidebar />
        <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 w-full lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">

        <div className="lg:mt-3 pt-2 bg-[#E1F9EC] rounded-[10px]">
            <div className="flex justify-between">
              <img src="/images/bro.png" className="lg:block hidden" alt="hero" />

              <div className="flex flex-col text-center">
                <h4 className="text-[#00B07B] text-[32px] font-[700] max-w-[561px] ">
                  Get admission to get loan
                </h4>
                <h6 className="text-[16px] font-[500] text-[#5A5A5A]">
                  Please ensure that you verify your school, country, and
                  eligibility for admission.
                </h6>
              </div>
              <img src="/images/bro1.png" className="lg:block hidden" alt="hero" />
            </div>
          </div>

          <div className="pt-10">
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
              <div className="bg-white py-10  rounded-[8px]">
              <div className="px-7">
               <div className="flex justify-center">
                  <span className="w-full border border-dotted border-[#00B07B] rounded-[48px] text-[#00B07B] text-[16px] text-center py-[10px] font-bold">EDUPREMIUM Study Loan</span>
                </div>
                <div className="pt-6">
                 
                  <p className="pt-2 text-center text-[14px] text-[#5E5B5B]">This service is available for students who may already have been granted an admission letter or offer letter to access this service , please verify on EDUSILVER that your Institution  is included in the list of institutions for which we provide study loans.</p>
                  <h1 className="text-[32px] font-bold mt-4 text-center">$250</h1>
                </div>
                <NavLink to={"/edu-premium-study-loan"}>
                <div className="flex justify-center mt-4">
                  <button className="w-full py-[10px] bg-[#00B07B] text-[16px] font-normal text-white rounded-[30px] ">Get Started</button>
                </div>
                </NavLink>
              </div>
               
                <hr className="my-5 h-[1px] bg-[#E9E9E9]"/>
                <div className="px-5">
                <h3 className="text-[#282828] text-[18px] font-bold">Package Benefit</h3>
                <div className="mt-3">
                <div className="flex space-x-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Professional guidance  on loan eligible institutions</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Professional processing of loans on your behalf</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Access to loans that  do not require guarantor or Collateral</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Access to loans with good and flexible repayment terms</h3>
                </div>

                </div>
                </div>
              </div>

             <div className="bg-[#00B07B] py-10  rounded-[8px]">
              <div className="px-7">
               <div className="flex justify-center">
                  <span className="w-full border border-dotted border-[#fff] rounded-[48px] text-[#fff] text-[16px] text-center py-[10px] font-bold">EDUPREMIUM Plus</span>
                </div>
                <div className="pt-6">
                 
                  <p className="pt-2 text-center text-[14px] text-white">This is intended for student who did not have admission but have the necessary document to get admission and the loan </p>
                  <h1 className="text-[32px] font-bold mt-4 text-center text-white">$450</h1>
                </div>
              
                  <a href='loans-premium-eligibility'>
                  <div className="flex justify-center mt-4">
                  <button className="w-full py-[10px] bg-[#fff] text-[16px] font-normal text-[#00B07B] rounded-[30px] ">Get Started</button>
                </div>
                  </a>
                
             
                
              </div>
               
                <hr className="my-5 h-[1px] bg-[#C2EDE0]"/>
                <div className="px-5">
                <h3 className="text-[#FFFCFC] text-[18px] font-bold">Package Benefit</h3>
                <div className="mt-3">
                <div className="flex space-x-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Access to over 2500 institutions and over 40 countries</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Admission processing for 6 institutions </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Professional CV Revamp</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Personal Statement  Writing for all 6 application </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Personal processing of the loan on your behalf </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Professional guidance on loan required document  </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]">Access to loans that do not required guarantor or collateral</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKWHITE} />
                <h3 className="text-[#FFFCFC] text-[10px]"> Access to loans with good and flexible  repayment term</h3>
                </div>

                </div>
                </div>
              </div>


              <div className="bg-white py-10  rounded-[8px]">
              <div className="px-7">
               <div className="flex justify-center">
                  <span className="w-full border border-dotted border-[#00B07B] rounded-[48px] text-[#00B07B] text-[16px] text-center py-[10px] font-bold">EDUPREMIUM School Processing</span>
                </div>
                <div className="pt-6">

                  <p className="pt-2 text-center text-[14px] text-[#5E5B5B]">This services  is available for student who have the necessary document to apply  for admission </p>
                  <h1 className="text-[32px] font-bold mt-4 text-center">$200</h1>
                </div>
                <NavLink to={"/edu-premium-school"}>
                <div className="flex justify-center mt-4">
                  <button className="w-full py-[10px] bg-[#00B07B] text-[16px] font-normal text-white rounded-[30px] ">Get Started</button>
                </div>
                </NavLink>             
              </div>
               
                <hr className="my-5 h-[1px] bg-[#E9E9E9]"/>
                <div className="px-5">
                <h3 className="text-[#282828] text-[18px] font-bold">Package Benefit</h3>
                <div className="mt-3">
                <div className="flex space-x-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Access to over 2500 institutions and over 40 countries</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Application to about 6 institutions that are eligible for loans  </h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">CV revamp</h3>
                </div>
                <div className="flex space-x-2 mt-2">
                <SvgElement type={icontypesEnum.CHECKGREEN} />
                <h3 className="text-[#282828] text-[10px]">Personal statement  writing for all 6 institutions </h3>
                </div>

                </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
    </div>
  )
}

export default PremiumLoanPackage