import React, { useState, useEffect } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from "../../ReuseableComponent/HeaderTitle";
import { toast } from "react-toastify";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut, getElementsAtEvent } from "react-chartjs-2";
import Modal from "react-responsive-modal";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SCHOOLDATA from "../../assets/schoolsData.json";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { DashboardApi } from "../../../apis/DashboardApi";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { PaymentApis } from "../../../apis/paymentApis";
import markDone from "../../assets/markDone.json";
import Lottie from "lottie-react";
import { TbCopy } from "react-icons/tb";
import { MdOutlineMail } from "react-icons/md";
import { TbBrandWhatsapp } from "react-icons/tb";

const PremiumLoanForm = () => {
  const [dataArray, setDataArray] = useState<number[]>([0, 100]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOption3, setSelectedOption3] = useState("");
  const [selectedOption4, setSelectedOption4] = useState("");
  const [selectedOption5, setSelectedOption5] = useState("");
  const [stateOptions, setStateOptions] = useState<any>([]);
  const [stateOptionsTwo, setStateOptionsTwo] = useState<any>([]);
  const [stateOptionsThree, setStateOptionsThree] = useState<any>([]);
  const [stateOptionsFour, setStateOptionsFour] = useState<any>([]);
  const [stateOptionsFive, setStateOptionsFive] = useState<any>([]);


  const optionCountries: any = [];
  const optionState: any = [];
  SCHOOLDATA.map((item) => {
    optionCountries.push({ label: item.name, value: item.name });
  });

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      height: 46,
      minHeight: 46,
      background: "#FFFFFF",
      color: "#1F2337",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#1F2337", // Custom colour
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    menu: (base: any) => ({
      ...base,
      background: "white",
      color: "#1F2337",
    }),
    input: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
    singleValue: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "black",
      };
    },
  };

  function customTheme(theme: any) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#1DB459",
        primary: "#00B07B",
      },
    };
  }




  const handleSelectChange = (name: any, option: any) => {
    setUserdata((prevData: any) => ({ ...prevData, [name]: option.value }));

    if (name === "region1") {
      const selectedCountry = SCHOOLDATA.find(
        (country) => country.name === option.value
      );
      const states = selectedCountry
        ? selectedCountry.details.map((state) => ({
          label: state.name,
          value: state.name,
        }))
        : [];
      setStateOptions(states);
    }
  };

  const handleSelectChangeTwo = (name: any, option: any) => {
    setUserdata((prevData: any) => ({ ...prevData, [name]: option.value }));

    if (name === "region2") {
      const selectedCountry = SCHOOLDATA.find(
        (country) => country.name === option.value
      );
      const states = selectedCountry
        ? selectedCountry.details.map((state) => ({
          label: state.name,
          value: state.name,
        }))
        : [];
      setStateOptionsTwo(states);
    }
  };

  const handleSelectChangeThree = (name: any, option: any) => {
    setUserdata((prevData: any) => ({ ...prevData, [name]: option.value }));

    if (name === "region3") {
      const selectedCountry = SCHOOLDATA.find(
        (country) => country.name === option.value
      );
      const states = selectedCountry
        ? selectedCountry.details.map((state) => ({
          label: state.name,
          value: state.name,
        }))
        : [];
      setStateOptionsThree(states);
    }
  };

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      do_you_have_lang_certificate: e.target.value,
    }));
  };

  const handleOptionChange1 = (e: any) => {
    setSelectedOption1(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      certificate: e.target.value,
    }));
  };

  const handleOptionChange2 = (e: any) => {
    setSelectedOption2(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      certificate: e.target.value,
    }));
  };

  const handleOptionChange3 = (e: any) => {
    setSelectedOption3(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      certificate: e.target.value,
    }));
  };

  const handleOptionChange4 = (e: any) => {
    setSelectedOption(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      certificate: e.target.value,
    }));
  };

  const handleOptionChange5 = (e: any) => {
    setSelectedOption(e.target.value);
    setUserdata((prevData: any) => ({
      ...prevData,
      certificate: e.target.value,
    }));
  };
  ChartJs.register(...registerables, Tooltip, Title, ArcElement, Legend);

  // Custom plugin to draw text in the center
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: function (chart: any) {
      const { width, height, ctx } = chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${(dataArray[0] / dataArray.reduce((a, b) => a + b, 0)) * 100
        }%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const data = {
    datasets: [
      {
        data: dataArray,
        backgroundColor: ["#00B07B", "#D9D9D9"],
        cutout: "70%", // Adjust this value to make the doughnut thinner
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Send", "Receive"],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const [currency, setCurrency] = useState<'local' | 'foreign'>('local');
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes in seconds

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const [copiedField, setCopiedField] = useState<string | null>(null);
  const copyToClipboard = (text: string, field: string) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  const [openPayment, setOpenPayment] = useState(false);
  const onOpenPaymentModal = () => {
    // e.preventDefault();
    setOpenPayment(true);
  };
  const onClosePaymentModal = () => {
    setOpenPayment(false);
    onOpenPaymentProofModal();
  };
  const [openPaymentProof, setOpenPaymentProof] = useState(false);
  const onOpenPaymentProofModal = () => {
    // e.preventDefault();
    setOpenPaymentProof(true);
  };
  const onClosePaymentProofModal = () => {
    setOpenPaymentProof(false);
    setShowScreen(1)
    window.location.reload()
  };



  const [open, setOpen] = useState(false);
  const onOpenModal = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const [openDone, setOpenDone] = useState(false);
  const onOpenDoneModal = (e: any) => {
    e.preventDefault();
    setOpenDone(true);
  };
  const onCloseDoneModal = () => setOpenDone(false);

  const [successOpen, setSuccessOpen] = useState(false);
  const onOpenSuccessModal = () => setSuccessOpen(true);
  const onCloseSuccessModal = () => setSuccessOpen(false);

  // const navigate = useNavigate();
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };
  const [selectedPics2, setSelectedPics2] =
    React.useState<any>("No selected file");
  const changeHandler2 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "certificate") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "certificate") {
          setSelectedPics2(event.target.files[0]);
        }
      }
    }
  };

  const [selectedPics3, setSelectedPics3] =
    React.useState<any>("No selected file");
  const changeHandler3 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "language_certificate") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "language_certificate") {
          setSelectedPics3(event.target.files[0]);
        }
      }
    }
  };

  const [selectedPics4, setSelectedPics4] =
    React.useState<any>("No selected file");
  const changeHandler4 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "certificate") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "certificate") {
          setSelectedPics4(event.target.files[0]);
        }
      }
    }
  };

  const [selectedPics5, setSelectedPics5] =
    React.useState<any>("No selected file");
  const changeHandler5 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "language_certificate") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "language_certificate") {
          setSelectedPics5(event.target.files[0]);
        }
      }
    }
  };
  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [userData, setUserdata] = React.useState({
    full_name: "",
    email: "",
    age: "",
    gender: "",
    marital_status: "",
    employment_status: "",
    your_institution: "",
    job_title: "",
    type_of_certificate: "",
    certificate: selectedPics4,
    do_you_have_lang_certificate: "",
    language_certificate: selectedPics5,
    cgpa_level: "",
    region1: "",
    school1_in_region1: "",
    school12_in_region1: "",
    region2: "",
    school1_in_region2: "",
    school2_in_region2: "",
    region3: "",
    school1_in_region3: "",
    school2_in_region3: "",
  });

  // console.log(userData)
  const [singleSchool, setSingleSchool] = React.useState<any>([]);
  React.useEffect(() => {
    DashboardApi.getSingleSchoolForm(userLoginData?.data?.user_data?.id)
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setSingleSchool(response?.data?.data);
          setUserdata(response?.data?.data)
          // console.log(response?.data.data);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  // console.log(singleSchool);

  // const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
  //   // navigate("/loans-gold-plan")
  //   onOpenModal(e);
  // };


  const [loader, setLoader] = React.useState<any>(false);
  const [loader2, setLoader2] = React.useState<any>(false);
  const [loader3, setLoader3] = React.useState<any>(false);

  const handleSubmit = React.useCallback(

    (e: any) => {
      e.preventDefault();
      setLoader(true)
      const userDatas = new FormData();
      userDatas.append('full_name', userData?.full_name);
      userDatas.append('email', userData?.email);
      userDatas.append('age', userData?.age);
      userDatas.append('gender', userData?.gender);
      userDatas.append('marital_status', userData?.marital_status);
      userDatas.append('employment_status', userData?.employment_status);
      // userDatas.append('your_institution', userLoginData?.data?.user_data?.email);
      userDatas.append('your_institution', userData?.your_institution);
      userDatas.append('job_title', userData?.job_title);
      userDatas.append('type_of_certificate', userData?.type_of_certificate);
      userDatas.append('certificate', selectedPics4);
      userDatas.append('do_you_have_lang_certificate', userData?.do_you_have_lang_certificate);
      userDatas.append('language_certificate', selectedPics5 || "");
      userDatas.append('cgpa_level', userData?.cgpa_level);
      userDatas.append('region1', userData?.region1);
      userDatas.append('school1_in_region1', userData?.school1_in_region1);
      userDatas.append('school12_in_region1', userData?.school12_in_region1);
      // userDatas.append('utility_type', userDatas?.utility_type);
      // userDatas.append('utility_bill_picture', selectedPics3);
      userDatas.append('region2', userData?.region2);
      userDatas.append('school1_in_region2', userData?.school1_in_region2);
      userDatas.append('school2_in_region2', userData?.school2_in_region2);
      userDatas.append('region3', userData?.region3);
      userDatas.append('school1_in_region3', userData?.school1_in_region3);
      userDatas.append('school2_in_region3', userData?.school2_in_region3);
      // userDatas.append('country_of_residence', userData?.country_of_residence ? userData?.country_of_residence : singleCredit?.country_of_residence);


      DashboardApi.createSchoolForm(userDatas).then(
        (response: any) => {
          if (response?.data) {
            setLoader(false)
            toast.success('Your School form is successful');
            // onOpenModal(e);
            // onOpenDoneModal(e);
            onOpenPaymentModal()

          }
        }
      ).catch((err) => {
        if (err?.response?.status == 422) {
          toast.error('Form not rightly filled. Kindly Cross check.');
          setLoader(false)
        } else {
          toast.error('Some error occured.Please try again');
          setLoader(false)
        }


      }).finally(() => {

      });
    },
    [selectedPics4, selectedPics5, userData, loader]
  );

  // const handleSubmit2 = (e: React.ChangeEvent<HTMLFormElement>) => {

  //   e.preventDefault();
  //   onCloseModal();
  //   onOpenSuccessModal();
  // };

  const handleSubmit2 = React.useCallback((e: any) => {
    e.preventDefault();
    setLoader2(true);

    const formData = new FormData();
    // formData.append('amount', amount)
    formData.append("amount", "450"); // Hardcoded amount
    PaymentApis.creditHistoryPremiumLoanPayment(formData)
      .then((response: any) => {
        if (response?.data) {
          setLoader2(false);
          // console.log(response?.data)
          window.location.replace(response?.data?.data?.data?.link);
          //                 onCloseModal();
          // onOpenSuccessModal();
        }
      })
      .catch(function (error) {
        setLoader2(false);

        toast.error("An error occured");
      });
  }, []);
  const navigate = useNavigate();
  const [openModalNoti, setOpenModalNoti] = useState(true);
  // const onOpenModalNoti = () => setOpenModalNoti(true);
  const onCloseModalNoti = () => setOpenModalNoti(false);
  // const onCloseModalNoti = () => {
  //     navigate(-1)
  // }

  // const handleSubmit3 = React.useCallback((e: any) => {
  //   e.preventDefault();
  //   setLoader3(true);

  //   const formData = new FormData();  
  //   formData.append("amount", "250"); // Hardcoded amount
  //   PaymentApis.creditHistoryPremiumLoanPayment(formData)
  //     .then((response: any) => {
  //       if (response?.data) {
  //         setLoader3(false);    
  //         window.location.replace(response?.data?.data?.data?.link);      
  //       }
  //     })
  //     .catch(function (error) {
  //       setLoader3(false);
  //       toast.error("An error occured");
  //     });
  // }, []);

  let [showScreen, setShowScreen] = React.useState<any>(1);

  const [rate, setRate] = useState<number>(0);
  const [convertedValue, setConvertedValue] = useState<number>(0);

  useEffect(() => {
    PaymentApis.getNGNRate().then((response: any) => {
      if (response?.data?.result) {
        const baseRate = parseFloat(response.data.result);
        if (!isNaN(baseRate)) {
          const currentRate = baseRate + 59.29;
          setRate(currentRate);
          setConvertedValue(currentRate * 450);
        }
      }
    }).catch(error => {
      console.error('Error fetching rate:', error);
    });
  }, []);

  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        {/* <Modal open={openModalNoti} onClose={onCloseModalNoti} center>
          <div className='md:max-w-md mt-6'>

            <div className=" w-full">
              <div className='flex justify-center'>
                <img src="/images/Dashbord/student-modal.png" alt="coming soon" className="" />
              </div>

            </div>

            <div className='flex justify-between mt-6'>
              <NavLink to="https://api.whatsapp.com/send?phone=2347066169657"
                target="_blank"
                className="text-white  bg-[#04B241] flex justify-center items-center py-3 px-6 rounded-[4px]">
                Speak to an expert
              </NavLink>

              <button
                onClick={onCloseModalNoti}
                className="text-[#04B241]  bg-[#ffffff] border border-[#04B241] flex justify-center items-center py-3 px-6 rounded-[4px]">
                Continue filling the form
              </button>
            </div>
          </div>

        </Modal> */}

        <div className="lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <HeaderTitle title="Loan" />

          <div className="flex justify-center">
            <div className="pt-3 pb-7">
              <h3 className="text-[#000000] text-center text-[36px] font-[600]">
                School Form
              </h3>
              {/* <h5 className="text-[#5F5D5D] text-center text-[20px] font-[500]">
              
              </h5> */}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className={showScreen === 1 ? "block " : "hidden"}>
              <div className="grid lg:grid-cols-12 gap-3">
                <div className="lg:col-span-8 bg-white pt-2 px-2 rounded-[10px]">
                  <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                    <h4 className="tex-[#000000] text-[16px] font-[500]">
                      Fill out the Form
                    </h4>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder="Answer"
                      name="full_name"
                      defaultValue={userData?.full_name}
                      onChange={handleChange}
                    //   disabled
                    />
                  </div>
                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Email
                    </label>
                    <input
                      type="email"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      defaultValue={userData?.email}
                      //   defaultValue={userDetails?.first_name}
                      onChange={handleChange}
                    //   disabled
                    />
                  </div>

                  <div className="flex w-full mt-4 gap-2">
                    <div className="relative w-full">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Gender
                      </label>

                      <select
                        id="gender"
                        name="gender"
                        onChange={handleChange}
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      >
                        <option selected>{userData?.gender}</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className="relative w-full ">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Age
                      </label>
                      <input
                        type="number"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        // placeholder="Answer"
                        name="age"
                        defaultValue={userData?.age}
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleChange}
                      //   disabled
                      />
                    </div>
                  </div>

                  <div className="flex w-full mt-4 gap-2">
                    <div className="relative w-full">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Marital status
                      </label>

                      <select
                        id="marital_status"
                        name="marital_status"
                        onChange={handleChange}
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      >
                        <option selected>{userData?.marital_status}</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="separated">Separated</option>
                        <option value="others">Others</option>
                        {/* <option value="complicated">Complicated</option> */}
                      </select>
                    </div>
                    {/* <div className="relative w-full ">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Employment status
                      </label>
                      <select
                        id="do_you_have_a_sponsor"
                        name="do_you_have_a_sponsor"
                        onChange={handleChange}
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      >
                        <option selected></option>
                        <option value="unemployed">Unemployed</option>
                        <option value="employed">Employed</option>
                      </select>
                    </div> */}
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Institution where you obtained your degree
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      // placeholder="Answer"
                      name="your_institution"
                      defaultValue={userData?.your_institution}
                      //   defaultValue={userDetails?.first_name}
                      onChange={handleChange}
                    //   disabled
                    />
                  </div>

                  <div className="flex w-full mt-4 gap-2">
                    <div className="relative w-full ">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Employment status
                      </label>
                      <select
                        id="employment_status"
                        name="employment_status"
                        onChange={handleChange}
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      >
                        <option selected>{userData?.employment_status}</option>
                        <option value="self-employed">Self Employed</option>
                        <option value="unemployed">Unemployed</option>
                        <option value="employed">Employed</option>
                      </select>
                    </div>
                    <div className="relative w-full">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        Job title
                      </label>
                      <input
                        type="text"
                        className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        // placeholder="Answer"
                        name="job_title"
                        defaultValue={userData?.job_title}
                        //   defaultValue={userDetails?.first_name}
                        onChange={handleChange}
                      //   disabled
                      />
                    </div>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Type of certificate
                    </label>
                    <select
                      id="type_of_certificate"
                      name="type_of_certificate"
                      onChange={handleChange}
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected>{userData?.type_of_certificate}</option>
                      <option value="HND">HND</option>
                      <option value="BSC">BSC</option>
                      <option value="BTECH">BTECH</option>
                      <option value="MSC">MSC</option>
                      {/* <option value="designer">Designer</option> */}
                    </select>
                  </div>

                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      Upload certificate
                    </label>
                    <div className="border-2 border-gray-300 border-dashed rounded-md pt-10 pb-8 h-[160px] text-center">
                      <label htmlFor="certificate">
                        <h3 className="text-[12px] font-medium">
                          {selectedPics4 !== "No selected file"
                            ? selectedPics4?.name
                            : "e.g, HND, BSC, MSC.e.t.c"}{" "}
                        </h3>
                        <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                          {selectedPics4 !== "No selected file"
                            ? ""
                            : "Not more than 3MB"}{" "}
                        </h3>
                        <div className="mt-5">
                          <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                            Choose a File
                          </span>
                        </div>
                        <input
                          id="certificate"
                          type="file"
                          accept="image/png, image/jpeg"
                          name="certificate"
                          onChange={changeHandler4}
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                      <h4 className="tex-[#000000] text-[16px] font-[500]">
                        Do you have language certificate
                      </h4>
                    </label>
                    <div className="w-full grid grid-cols-2 pt-3 gap-3">
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="yes"
                          name="do_you_have_lang_certificate"
                          value="yes"
                          checked={selectedOption === "yes"}
                          onChange={handleOptionChange}
                        />
                        <label htmlFor="yes">Yes</label>
                      </div>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="no"
                          name="do_you_have_lang_certificate"
                          value="no"
                          checked={selectedOption === "no"}
                          onChange={handleOptionChange}
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                  </div>
                  {selectedOption === "yes" && (
                    <div className="relative w-full mt-4">
                      <label className="block mb-2 text-[16px] text-[#667085]">
                        If yes upload document
                      </label>
                      <div className="border-2 border-gray-300 border-dashed rounded-md pt-10 pb-8 h-[160px] text-center">
                        <label htmlFor="language_certificate">
                          <h3 className="text-[12px] font-medium">
                            {selectedPics5 !== "No selected file"
                              ? selectedPics5?.name
                              : "e.g IELTS, TOEFLe .t.c"}{" "}
                          </h3>
                          <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                            {selectedPics5 !== "No selected file"
                              ? ""
                              : "Not more than 3MB"}{" "}
                          </h3>
                          <div className="mt-5">
                            <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                              Choose a File
                            </span>
                          </div>
                          <input
                            id="language_certificate"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="language_certificate"
                            onChange={changeHandler5}
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="relative w-full mt-4">
                    <label className="block mb-2 text-[16px] text-[#667085]">
                      CGPA Level
                    </label>

                    <select
                      id="cgpa_level"
                      name="cgpa_level"
                      onChange={handleChange}
                      defaultValue={userData?.cgpa_level}
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected>{userData?.cgpa_level}</option>
                      <option value="first class">First class</option>
                      <option value="second class upper">
                        Second class upper
                      </option>
                      <option value="second class lower">
                        Second class lower
                      </option>
                      <option value="third class">Third class</option>
                      <option value="pass">Pass</option>
                      <option value="complicated">Complicated</option>
                    </select>
                  </div>

                  <div className="flex justify-center mb-10 pt-7">
                    <button
                      disabled={userData.full_name === "" || userData.gender === ""
                        || userData.age === ""
                        || userData.marital_status === ""
                        || userData.employment_status === ""
                        || userData.your_institution === ""
                        || userData.job_title === ""
                        || userData.type_of_certificate === ""
                        || userData.certificate === ""
                        || userData.cgpa_level === ""
                        || (selectedOption === "yes" && userData.language_certificate === "")

                      }
                      onClick={() => setShowScreen(2)}
                      className="disabled:bg-gray-600 text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
                    >
                      Proceed
                    </button>
                  </div>
                </div>

                <div className="lg:col-span-4">
                  <div className="border rounded-[10px] h-fit  w-full flex justify-center relative">
                    <div>
                      <h4 className="text-[#000000] text-[20px] text-center pt-4 font-semibold">
                        EduPremium Stage
                      </h4>
                      <div className="p-4  md:w-[180px] md:h-[180px]">
                        <Doughnut
                          options={options}
                          data={data}
                          plugins={[centerTextPlugin]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <h4>
                          On Progress <span className="text-[#00B07B]">0%</span>
                        </h4>
                      </div>
                      <div className="flex justify-center mt-3 mb-10">
                        <h4 className=" text-center max-w-[200px]">
                          Form to check credit eligibility
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-[10px] mt-8">
                    <div className="bg-white rounded-t-[10px] py-2 flex justify-center">
                      <h4 className="text-[#000000] text-[20px]">
                        What to do next
                      </h4>
                    </div>
                    <div className="bg-[#00B07B] rounded-b-[10px] flex justify-center pt-3 pb-5">
                      <h4 className="text-white text-[18px] text-center max-w-[300px]">
                        Fill credit eligibility form
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={showScreen === 2 ? "block " : "hidden"}>
              <div className="grid lg:grid-cols-12 gap-3">
                <div className="lg:col-span-8 bg-white pt-2 px-2 rounded-[10px]">
                  <div>
                    <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                      <h4 className="tex-[#000000] text-[16px] font-[500]">
                        Choose the first region you want to study
                      </h4>
                    </div>

                    <div className="relative w-full mt-4">
                      <Select
                        name="region1"
                        options={optionCountries}
                        isSearchable
                        theme={customTheme}
                        styles={customStyles}
                        required
                        // defaultInputValue={props.customer!.country}
                        // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        placeholder="select country"
                        noOptionsMessage={() => "Country not found"}
                        onChange={(option) =>
                          handleSelectChange("region1", option)
                        }
                      />

                      {/* <select
                      id="do_you_have_a_sponsor"
                      name="do_you_have_a_sponsor"
                      onChange={handleChange}
                      placeholder="select option"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected>select option</option>
                      <option value="us">US</option>
                      <option value="europe">Europe</option>
                      <option value="canada">Canada</option>
                    </select> */}
                    </div>

                    <div className="mt-2">
                      <label className=" text-[#5F5D5D] mb-1 text-center text-[14px] font-[500] flex justify-center">
                        {/* <h4 className="tex-[#000000]  "> */}
                        Choose two schools from region 1{/* </h4> */}
                      </label>
                      <div className="w-full grid grid-cols-2 pt-1 gap-3">
                        <div className="relative w-full">
                          <CreatableSelect
                            name="school1_in_region1"
                            options={stateOptions}
                            isSearchable
                            isClearable
                            required
                            theme={customTheme}
                            styles={customStyles}
                            placeholder="select a school"
                            noOptionsMessage={() => "select a Country"}
                            onChange={(option) =>
                              handleSelectChange("school1_in_region1", option)
                            }
                          />
                          {/* <select
                          value={selectedOption}
                          onChange={handleOptionChange}
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:opacity-50 appearance-none focus:outline-none focus:border-primary-500"
                        >
                          <option value=""></option>
                          <option value="futa">Futa</option>
                          <option value="lautech">Lautech</option>
                          <option value="yabatech">Yabatech</option>
                          <option value="unilag">Unilag</option>
                          <option value="oou">OOU</option>
                        </select> */}
                          <label
                            htmlFor="yes"
                            className="text-center flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-2 text-[#000000]"
                          >
                            {/* {selectedOption ? "" : "+"} */}
                          </label>
                        </div>

                        <div className="relative w-full">
                          <CreatableSelect
                            name="school12_in_region1"
                            options={stateOptions}
                            isSearchable
                            isClearable
                            required
                            theme={customTheme}
                            styles={customStyles}
                            placeholder="select a school"
                            noOptionsMessage={() => "select a Country"}
                            onChange={(option) =>
                              handleSelectChange("school12_in_region1", option)
                            }
                          />
                          {/* <select
                          value={selectedOption1}
                          onChange={handleOptionChange1}
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:opacity-50 appearance-none focus:outline-none focus:border-primary-500"
                        >
                          <option value=""></option>
                          <option value="futa">Futa</option>
                          <option value="lautech">Lautech</option>
                          <option value="yabatech">Yabatech</option>
                          <option value="unilag">Unilag</option>
                          <option value="oou">OOU</option>
                        </select> */}
                          <label
                            htmlFor="yes"
                            className="text-center flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-2 text-[#000000]"
                          >
                            {/* {selectedOption1 ? "" : "+"} */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-6">
                    <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                      <h4 className="tex-[#000000] text-[16px] font-[500]">
                        Choose the second region you want to study
                      </h4>
                    </div>

                    <div className="relative w-full mt-4">
                      <Select
                        name="region2"
                        options={optionCountries}
                        isSearchable
                        theme={customTheme}
                        styles={customStyles}
                        required
                        // defaultInputValue={props.customer!.country}
                        // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        placeholder="select country"
                        noOptionsMessage={() => "Country not found"}
                        onChange={(option) =>
                          handleSelectChangeTwo("region2", option)
                        }
                      />
                      {/* <select
                      id="do_you_have_a_sponsor"
                      name="do_you_have_a_sponsor"
                      onChange={handleChange}
                      placeholder="select option"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected>select option</option>
                      <option value="us">US</option>
                      <option value="europe">Europe</option>
                      <option value="canada">Canada</option>
                    </select> */}
                    </div>

                    <div className="mt-2">
                      <label className=" text-[#5F5D5D] mb-1  text-center text-[14px] font-[500] flex justify-center">
                        {/* <h4 className="tex-[#000000]  "> */}
                        Choose two schools from region 2{/* </h4> */}
                      </label>
                      <div className="w-full grid grid-cols-2 pt-1 gap-3">
                        <div className="relative w-full">
                          <CreatableSelect
                            name="school1_in_region2"
                            options={stateOptionsTwo}
                            isSearchable
                            isClearable
                            required
                            theme={customTheme}
                            styles={customStyles}
                            placeholder="select a school"
                            noOptionsMessage={() => "select a Country"}
                            onChange={(option) =>
                              handleSelectChangeTwo(
                                "school1_in_region2",
                                option
                              )
                            }
                          />
                          {/* <select
                          value={selectedOption2}
                          onChange={handleOptionChange2}
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:opacity-50 appearance-none focus:outline-none focus:border-primary-500"
                        >
                          <option value=""></option>
                          <option value="futa">Futa</option>
                          <option value="lautech">Lautech</option>
                          <option value="yabatech">Yabatech</option>
                          <option value="unilag">Unilag</option>
                          <option value="oou">OOU</option>
                        </select> */}
                          <label
                            htmlFor="yes"
                            className="text-center flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-2 text-[#000000]"
                          >
                            {/* {selectedOption2 ? "" : "+"} */}
                          </label>
                        </div>

                        <div className="relative w-full">
                          <CreatableSelect
                            name="school2_in_region2"
                            options={stateOptionsTwo}
                            isSearchable
                            isClearable
                            required
                            theme={customTheme}
                            styles={customStyles}
                            placeholder="select a school"
                            noOptionsMessage={() => "select a Country"}
                            onChange={(option) =>
                              handleSelectChangeTwo(
                                "school2_in_region2",
                                option
                              )
                            }
                          />
                          {/* <select
                          value={selectedOption3}
                          onChange={handleOptionChange3}
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:opacity-50 appearance-none focus:outline-none focus:border-primary-500"
                        >
                          <option value=""></option>
                          <option value="futa">Futa</option>
                          <option value="lautech">Lautech</option>
                          <option value="yabatech">Yabatech</option>
                          <option value="unilag">Unilag</option>
                          <option value="oou">OOU</option>
                        </select> */}
                          <label
                            htmlFor="yes"
                            className="text-center flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-2 text-[#000000]"
                          >
                            {/* {selectedOption3 ? "" : "+"} */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-6">
                    <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                      <h4 className="tex-[#000000] text-[16px] font-[500]">
                        Choose the third region you want to study
                      </h4>
                    </div>

                    <div className="relative w-full mt-4">
                      <Select
                        name="region3"
                        options={optionCountries}
                        isSearchable
                        theme={customTheme}
                        styles={customStyles}
                        required
                        // defaultInputValue={props.customer!.country}
                        // className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        placeholder="select country"
                        noOptionsMessage={() => "Country not found"}
                        onChange={(option) =>
                          handleSelectChangeThree("region3", option)
                        }
                      />
                      {/* <select
                      id="do_you_have_a_sponsor"
                      name="do_you_have_a_sponsor"
                      onChange={handleChange}
                      placeholder="select option"
                      className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    >
                      <option selected>select option</option>
                      <option value="us">US</option>
                      <option value="europe">Europe</option>
                      <option value="canada">Canada</option>
                    </select> */}
                    </div>

                    <div className="mt-2">
                      <label className=" text-[#5F5D5D] mb-1  text-center text-[14px] font-[500] flex justify-center">
                        {/* <h4 className="tex-[#000000]  "> */}
                        Choose two schools from region 3{/* </h4> */}
                      </label>
                      <div className="w-full grid grid-cols-2 pt-1 gap-3">
                        <div className="relative w-full">
                          <CreatableSelect
                            name="school1_in_region3"
                            options={stateOptionsThree}
                            isSearchable
                            isClearable
                            required
                            theme={customTheme}
                            styles={customStyles}
                            placeholder="select a school"
                            noOptionsMessage={() => "select a Country"}
                            onChange={(option) =>
                              handleSelectChangeThree(
                                "school1_in_region3",
                                option
                              )
                            }
                          />
                          {/* <select
                          value={selectedOption4}
                          onChange={handleOptionChange4}
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:opacity-50 appearance-none focus:outline-none focus:border-primary-500"
                        >
                          <option value=""></option>
                          <option value="futa">Futa</option>
                          <option value="lautech">Lautech</option>
                          <option value="yabatech">Yabatech</option>
                          <option value="unilag">Unilag</option>
                          <option value="oou">OOU</option>
                        </select> */}
                          <label
                            htmlFor="yes"
                            className="text-center flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-2 text-[#000000]"
                          >
                            {/* {selectedOption4 ? "" : "+"} */}
                          </label>
                        </div>

                        <div className="relative w-full">
                          <CreatableSelect
                            name="school2_in_region3"
                            options={stateOptionsThree}
                            isSearchable
                            isClearable
                            required
                            theme={customTheme}
                            styles={customStyles}
                            placeholder="select a school"
                            noOptionsMessage={() => "select a Country"}
                            onChange={(option) =>
                              handleSelectChangeThree(
                                "school2_in_region3",
                                option
                              )
                            }
                          />
                          {/* <select
                          value={selectedOption5}
                          onChange={handleOptionChange5}
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:opacity-50 appearance-none focus:outline-none focus:border-primary-500"
                        >
                          <option value=""></option>
                          <option value="futa">Futa</option>
                          <option value="lautech">Lautech</option>
                          <option value="yabatech">Yabatech</option>
                          <option value="unilag">Unilag</option>
                          <option value="oou">OOU</option>
                        </select> */}
                          <label
                            htmlFor="yes"
                            className="text-center flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-2 text-[#000000]"
                          >
                            {/* {selectedOption5 ? "" : "+"} */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center mb-10 pt-7">
                    <button
                      disabled={loader
                        || userData.region1 === ""
                        || userData.school1_in_region1 === ""
                        || userData.school12_in_region1 === ""
                        || userData.region2 === ""
                        || userData.school1_in_region2 === ""
                        || userData.school2_in_region2 === ""
                        || userData.region3 === ""
                        || userData.school1_in_region3 === ""
                        || userData.school2_in_region3 === ""
                      }
                      type="submit"
                      onClick={handleSubmit}

                      //   onClick={() => setShowScreen(2)}
                      className="text-white disabled:bg-gray-600  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
                    >
                      {loader ? <LoadingSpinner /> : "Submit School"}
                    </button>
                  </div>
                </div>

                <div className="lg:col-span-4">
                  <div className="border rounded-[10px] h-fit  w-full flex justify-center relative">
                    <div>
                      <h4 className="text-[#000000] text-[20px] text-center pt-4 font-semibold">
                        EduPremium Stage
                      </h4>
                      <div className="p-4  md:w-[180px] md:h-[180px]">
                        <Doughnut
                          options={options}
                          data={data}
                          plugins={[centerTextPlugin]}
                        />
                      </div>
                      <div className="flex justify-center">
                        <h4>
                          On Progress <span className="text-[#00B07B]">0%</span>
                        </h4>
                      </div>
                      <div className="flex justify-center mt-3 mb-10">
                        <h4 className=" text-center max-w-[200px]">
                          Form to check credit eligibility
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-[10px] mt-8">
                    <div className="bg-white rounded-t-[10px] py-2 flex justify-center">
                      <h4 className="text-[#000000] text-[20px]">
                        What to do next
                      </h4>
                    </div>
                    <div className="bg-[#00B07B] rounded-b-[10px] flex justify-center pt-3 pb-5">
                      <h4 className="text-white text-[18px] text-center max-w-[300px]">
                        Fill credit eligibility form
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Modal open={open} onClose={onCloseModal} center>
          <div className=" flex justify-center">
            <h4 className="tex-[#000000] text-[16px] font-[500]">
              Payment for EduPremium
            </h4>
          </div>
          <div className="md:w-[400px]  body-font font-poppins">
            <div className="flex justify-center mt-9">
              <h4 className="text-center text-[30px] font-bold">
                $450
              </h4>
            </div>
            {/* <div className="flex justify-center mt-2">
              <h4 className="text-center text-[14px] "> You have the option to pay this amount in two installments. The first payment of $250 can be made now, and the remaining $200 can be paid upon receiving your admission.</h4>
            </div> */}
            <div className="flex space-x-2 justify-center  pt-7">
              <button
                onClick={(e: any) => handleSubmit2(e)}
                // onClick={onOpenPaymentModal}
                className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
              >
                {loader2 ? <LoadingSpinner /> : "Pay $450"}

              </button>

            </div>

          </div>
        </Modal>

        <Modal open={openPayment} onClose={onClosePaymentModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>

          <div className=" flex justify-start  mt-1">
            <h4 className="tex-[#000000] text-[16px]  font-[500]">
              Payment
            </h4>

          </div>
          <div className="md:w-[500px] body-font font-poppins">

            {/* Currency Toggle */}
            <div className="flex justify-center gap-8 mb-5 mt-4">
              <button
                className={`pb-1 text-[12px] ${currency === 'local'
                  ? 'text-[#00B07B] border-b-2 w-[200px] border-[#00B07B]'
                  : 'text-[#C1BDBD] '
                  }`}
                onClick={() => setCurrency('local')}
              >
                Local Currency For NGN Only
              </button>
              <button
                className={`pb-2 text-[12px] ${currency === 'foreign'
                  ? 'text-[#00B07B] border-b-2 w-[200px] border-[#00B07B]'
                  : 'text-[#C1BDBD] '
                  }`}
                onClick={() => setCurrency('foreign')}
              >
                Foreign Currency For Non NGN
              </button>
            </div>

            {/* Payment Amount */}
            <div className="bg-[#F7F7F7] rounded-[8px] pt-3 pb-1 text-center mb-5">
              <p className="text-[14px] text-[#5A5A5A]">Payment</p>
              <p className="text-[28px] font-semibold text-[#00B07B]">$450</p>
            </div>

            {/* local page */}
            <div className={currency == 'local' ? "block " : "hidden"}>
              <div className=" mt-[20px] mb-8">
                <div className='border border-[#D6D9DC] rounded-[10px] px-2 py-2'>

                  <div className='flex justify-between'>
                    <h3 className='font-semibold text-[20px] text-[000]'>Summary</h3>
                    <span className="text-sm text-center text-[#B00000] border border-[#B00000] rounded-[8px] pt-1 px-4">
                      Expire in {formatTime(timeLeft)}

                    </span>
                  </div>

                  {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Fee</h4>
                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $22</h3>
                  </div> */}

                  <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Rate</h4>
                    <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>
                      ₦{rate.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </h3>
                  </div>

                  <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>You will transfer</h4>
                    <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>
                      ₦{Math.ceil(convertedValue).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </h3>
                  </div>

                </div>
              </div>

              {/* Bank Details */}
              <div className="space-y-4 mb-8">
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Name</span>
                  <span className="font-medium text-[14px]">AFRIPRO PLATFORM NIG LTD</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Number</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">1026908037</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('1026908037', 'account')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'account' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>

                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Bank Name</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">United Bank of Africa (UBA)</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('United Bank of Africa (UBA)', 'bank')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'bank' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <button onClick={onClosePaymentModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                I have make payment
              </button>
            </div>

            {/* foreign page */}
            <div className={currency == 'foreign' ? "block " : "hidden"}>
              <div className=" mt-[20px] mb-8">
                <div className='border border-[#D6D9DC] rounded-[10px] px-2 py-2'>

                  <div className='flex justify-between'>
                    <h3 className='font-semibold text-[20px] text-[000]'>Summary</h3>
                    {/* <span className="text-sm text-center text-[#B00000] border border-[#B00000] rounded-[8px] pt-1 px-4">
                                                Expire in {formatTime(timeLeft)}
        
                                            </span> */}
                  </div>

                  {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Fee</h4>
                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $22</h3>
                  </div> */}

                  {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                            <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Rate</h4>
                                            <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $1= ₦1,585.99 </h3>
                                        </div> */}

                  <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>You will transfer</h4>
                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'>$450 </h3>
                  </div>

                </div>
              </div>

              {/* Bank Details */}
              <div className="space-y-4 mb-8">
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Name</span>
                  <span className="font-medium text-[14px]">AFRIPRO OY</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Number</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">8312818556</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('8312818556', 'acct')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'acct' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Account Type</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">Checking</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('Checking', 'type')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'type' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Bank Name</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">WISE</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('WISE', 'bank')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'bank' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Routing Number</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">026073150</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('026073150', 'routing')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'routing' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Swift/BIC</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">CMFGUS33</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('CMFGUS33', 'swift')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'swift' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Bank Address</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">Community Federal Savings Bank, <br />89-16 Jamaica Ave, Woodhaven, <br />NY, 11421, United State</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('Community Federal Savings Bank,89-16 Jamaica Ave, Woodhaven,NY, 11421, United State', 'address')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'address' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[#727272] text-[14px]">Recipient Address</span>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-[14px]">Lotankatu 3, Espoo, 02650, Finland</span>
                    <div className="relative">
                      <button
                        onClick={() => copyToClipboard('Lotankatu 3, Espoo, 02650, Finland', 'reciAddress')}
                        className="text-[#000000] hover:text-gray-800 pt-0.5"
                      >
                        <TbCopy />

                      </button>
                      {copiedField === 'reciAddress' && (
                        <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <button onClick={onClosePaymentModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                I have make payment
              </button>
            </div>

          </div>
        </Modal>

        <Modal open={openPaymentProof} onClose={onClosePaymentProofModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>
          <div className='md:w-[350px] w-[300px]'>
            <div className=" flex justify-start mt-7">
              <h4 className="tex-[#000000] md:text-[18px] text-[14px] font-bold">
                Proof of Payment
              </h4>

            </div>
            <div className='flex  mt-5'>
              <p className='text-[14px] text-[#898989] '>Kindly send your receipt to any of the following platform to confirm your payment  </p>
            </div>

            <div className=' mt-5'>
              <div className='flex gap-2'>
                <MdOutlineMail size={20} />
                <a href="mailto:customersupport@goafripay.com" className=' underline underline-offset-2 text-[14px]'>customersupport@goafripay.com</a>
              </div>
              <div className='flex gap-2 mt-3'>
                <TbBrandWhatsapp size={20} />
                <NavLink to="https://api.whatsapp.com/send?phone=2348131470992" target='_blank'>
                  <span className=' underline underline-offset-2 text-[14px]'>+234 813 147 0992</span>
                </NavLink>
              </div>
            </div>
            <div>

              <div className='mt-5 flex justify-center'>
                <button onClick={onClosePaymentProofModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                  Okay
                </button>

              </div>
            </div>
          </div>

        </Modal>

        <Modal open={openDone} onClose={onCloseDoneModal} center>
          <div className='w-[500px]'>

            <div className='flex justify-center mt-4'>
              <div>
                <Lottie animationData={markDone} loop={true} />
              </div>
            </div>

            <div className=" flex justify-center mt-5">
              <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                Admission application submitted
              </h4>

            </div>
            <div>
              <p>You application for Loan processing have been submitted successfully </p>
            </div>

            <div className='mt-5'>
              <button
                onClick={onCloseDoneModal}
                className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Submit</button>
            </div>

          </div>

        </Modal>

        <Modal open={successOpen} onClose={onCloseSuccessModal} center>
          <div className="md:w-[500px]  body-font font-poppins">
            <div className="flex justify-center mt-9">
              <h4 className="text-center text-[30px] font-bold">
                Payment Successful
              </h4>
            </div>
            <div className="flex justify-center mt-2">
              <h4 className="text-center text-[20px] max-w-[450px]">
                Your payment for credit eligibility has been confirmedand is
                currently under review
                <br />
                Congratulation
              </h4>
            </div>
            <div className="flex justify-center">
              <IoIosCheckmarkCircleOutline className="w-[150px] h-[150px] text-[#00B07B]" />
            </div>
            <div className="flex justify-center  pt-7">
              <Link
                to="/loans-premium-success"
                className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full"
              >
                Proceed
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PremiumLoanForm;
