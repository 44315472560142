import { AxiosGlobal } from "../shared/axios";
import { AxiosPromise } from "axios";
import configs from "../../configs";
import { store } from "../../Components/store/store";

export class DashboardLiveApi extends AxiosGlobal {

   

    uploadBankDoc(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/upload-bank-docs`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    uploadKyc(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/kyc-verify`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    applyStudentLoan(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/apply-student-loan`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    createDynamicAccount(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/providus/create-dynamic-account`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }



    makeSchoolPayment(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/make-school-payment`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    addSchool(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/add-school`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  

    getAllSchool(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-all-schools?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getSchoolList(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-school-list`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getPaymentHistory(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/payment-history?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getPendingPaymentHistory(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/pending-payment-history`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getPaymentDetail(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/get-payment-details/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    verifyLoan(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/verify-loan`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    verifyKyc(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/verify-kyc`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    makePayment(amount: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/make-payment`, amount, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 


    makePaymentFromWallet(amount: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/make-school-payment-from-wallet`, amount, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    makePaymentWithInterswitch(amount: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/make-payment-with-interswitch`, amount, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 


    generateQuote(data: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/flutterwave/get-exchange-rate?amount=${data?.amount}&from=${data?.from}&to=${data?.to}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    swap(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/monieverse/swap`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    getCallback(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/callback?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  


    getDashboardData(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-dashboard-data`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  


    verifyInterswitchPayment(reference: any,amount:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/verify-payment-with-interswitch?reference=${reference}&amount=${amount}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    createCreditEligibility(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/credit-eligibility/create-credit-eligibility`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    getSingleCreditEligibity(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/credit-eligibility/get-single-credit-form/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    createSchoolForm(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/credit-eligibility/create-school-form`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    applyStudyLoan(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/apply-study-loan`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 
    admissionProcess(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/admission-process`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    getSingleSchoolForm(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/credit-eligibility/get-single-school-form/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    


}