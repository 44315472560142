import React, {useState} from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { AuthApis } from "../../apis/authApis";
import { login } from "../../reducer/loginSlice";
import { NavLink, useNavigate,Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'

export default function Navbar({ title }) {
  const [toggle, setToggle] = useState(false)

  const dispatch = useDispatch();
  const userLoginData = useSelector((state) => state.data.login.value);


  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();
  // && userLoginData.data?.user_data?.isVerified == 'true'

  // React.useEffect(() => {
  //   if(!userLoginData?.token){
  //     dispatch(login([]))
  //     navigate('/sign-in');
  //   }
     
  // }, []);

  // React.useEffect(() => {
  //   if(userLoginData.data?.user_data?.status == 'pending'){
  //     navigate("/kyc")
  //   }else if(userLoginData.data?.user_data?.status == 'inReview'){
  //     navigate("/kyc")
  //   }
  // }, []);

  const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

  const logOut = () => {
    AuthApis.logout('').then(
      (response) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/sign-in');

        }
      }
    ).catch(function (error) {
      dispatch(login([]))
      navigate('/sign-in');
    }).finally(function(){
      dispatch(login([]))
      navigate('/sign-in');
    }
      
    )

  };


  
  return (
    <>
      <div className="relative bg-lightBlue-600 md:pt-12 pb-2">
        {/* Navbar */}
        <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center  p-4 lg:shadow-lg md:shadow-lg">
          <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
            {/* Brand */}
            <a
              className="text-[#1DB459] text-[20px]  hidden lg:inline-block font-semibold"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              {title}
            </a>


            {/* Form */}
            {/* <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
              <div className="relative flex w-full flex-wrap items-stretch">
                <div class="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                </div>
                <input type="text" id="simple-search" class=" border border-gray-300 text-gray-500 text-sm rounded-full block w-full pl-4 p-2  " required />

              </div>
            </form> */}
            {/* User */}
            {/* <ul className="flex-col md:flex-row list-none items-center hidden md:flex md:space-x-4">
              <div>
                <img src="/images/Dashboard/icon-notify.svg" alt="hero" />
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#f48120" d="m20 8l-8 5l-8-5V6l8 5l8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z" /></svg>
              </div>
            </ul> */}
          </div>
          <div className="md:pr-14 md:flex space-x-2 hidden">
         
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="none" d="M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0M20.5 12.5A4.5 4.5 0 1 1 16 8a4.5 4.5 0 0 1 4.5 4.5"/><path fill="#6e7093" d="M26.749 24.93A13.99 13.99 0 1 0 2 16a13.899 13.899 0 0 0 3.251 8.93l-.02.017c.07.084.15.156.222.239c.09.103.187.2.28.3c.28.304.568.596.87.87c.092.084.187.162.28.242c.32.276.649.538.99.782c.044.03.084.069.128.1v-.012a13.901 13.901 0 0 0 16 0v.012c.044-.031.083-.07.128-.1c.34-.245.67-.506.99-.782c.093-.08.188-.159.28-.242c.302-.275.59-.566.87-.87c.093-.1.189-.197.28-.3c.071-.083.152-.155.222-.24ZM16 8a4.5 4.5 0 1 1-4.5 4.5A4.5 4.5 0 0 1 16 8M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0"/></svg>
         <div onClick={() => setToggle((prev) => (!prev))} className="flex space-x-2 cursor-pointer relative">
         {/* <h3 className="text-[14px] text-[#6E7093] mt-2">Samuel</h3>  */}
         <h3 className="text-[12px] text-[#6E7093] mt-2">{userLoginData?.name}</h3>
         <span className="mt-1.5">
                      {toggle ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#6E7093" d="m16.9 13.4l-4.2-4.2c-.4-.4-1-.4-1.4 0l-4.2 4.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.5-3.5l3.5 3.5c.2.2.4.3.7.3c.3 0 .5-.1.7-.3c.4-.4.4-1 0-1.4z" /></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#6E7093" d="M12 15.121a.997.997 0 0 1-.707-.293L7.05 10.586a1 1 0 0 1 1.414-1.414L12 12.707l3.536-3.535a1 1 0 0 1 1.414 1.414l-4.243 4.242a.997.997 0 0 1-.707.293Z" /></svg>
                      }
                    </span>
          </div>
       
          <div className={`${toggle ? 'flex' : 'hidden'} absolute -bottom-24 z-10 border border-gray-100 rounded-md bg-white shadow-lg`}>
            <div className="pt-3 pl-4 px-10 pb-4">
              <div className="mt-1 mb-3 text-[16px] cursor-pointer hover:text-[#6E7093]">
              <Link to="/profile" >
              Profile
              </Link>
              </div>
              <div className="mt-1 mb-3 text-[16px] cursor-pointer hover:text-[#6E7093]">
              <div 
              // onClick={logOut} 
              onClick={onOpenModal}
              >
              Log Out
              </div>
              </div>
             


            </div>
                  {/* <div className=' font-poppins flex flex-col justify-center flex-1'>
                    <NavLink to={"/study-in-finnish"} onClick={() => { setIsOpen(!isOpen); setToggle(false) }} className='block font-normal text-[12px] cursor-pointer text-green-700 hover:text-gray-900 hover:font-semibold mb-4'>Learn in Finnish (No Tuition Fees Paid)</NavLink>
                    <NavLink to={"/study-in-english"} onClick={() => { setIsOpen(!isOpen); setToggle(false) }} className=' block text-[12px] font-normal cursor-pointer text-green-700 hover:text-gray-900 hover:font-semibold mb-3'>Learn in English (Tuition Fees Paid)</NavLink>
                  </div> */}
                </div>
          </div>
        </nav>

         <Modal open={open} onClose={onCloseModal} center styles={{
                  modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                  },
                }}>
                  <div className='md:max-w-md  body-font font-poppins'>
                    <div className="flex flex-col mt-2">
                      <div className="flex justify-center">
                        <svg width="70" height="70" viewBox="0 0 91 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.3409 91.9999C7.49716 91.9999 5.0636 90.9999 3.04023 88.9997C1.01686 86.9996 0.00344697 84.5922 0 81.7777V10.2222C0 7.41111 1.01341 5.00548 3.04023 3.00533C5.06705 1.00518 7.50061 0.0034074 10.3409 0H41.3636C42.8286 0 44.0574 0.490667 45.0502 1.472C46.0429 2.45333 46.5375 3.66637 46.5341 5.11111C46.5306 6.55585 46.0343 7.77059 45.045 8.75533C44.0557 9.74007 42.8286 10.229 41.3636 10.2222H10.3409V81.7777H41.3636C42.8286 81.7777 44.0574 82.2684 45.0502 83.2497C46.0429 84.231 46.5375 85.4441 46.5341 86.8888C46.5306 88.3336 46.0343 89.5483 45.045 90.533C44.0557 91.5178 42.8286 92.0067 41.3636 91.9999H10.3409ZM73.2912 51.1111H36.1932C34.7282 51.1111 33.5011 50.6204 32.5118 49.6391C31.5225 48.6577 31.0262 47.4447 31.0227 46C31.0193 44.5552 31.5156 43.3422 32.5118 42.3609C33.508 41.3795 34.7351 40.8889 36.1932 40.8889H73.2912L63.5966 31.3055C62.6487 30.3685 62.1747 29.2185 62.1747 27.8555C62.1747 26.4926 62.6487 25.3 63.5966 24.2778C64.5445 23.2555 65.7509 22.7223 67.2159 22.678C68.6809 22.6337 69.9304 23.1244 70.9645 24.15L89.4489 42.4222C90.4829 43.4444 91 44.637 91 46C91 47.3629 90.4829 48.5555 89.4489 49.5777L70.9645 67.8499C69.9304 68.8722 68.7033 69.3628 67.2831 69.3219C65.863 69.2811 64.6341 68.7478 63.5966 67.7222C62.6487 66.7 62.1971 65.4869 62.2419 64.0831C62.2867 62.6792 62.7814 61.5071 63.7258 60.5666L73.2912 51.1111Z" fill="#FF212E" />
                        </svg>
                      </div>
        
                      <div className="mt-4">
                        <div>
                          <h5 className="text-[15px] text-[#979797]"> Are you sure you want to log out ?</h5>
                        </div>
                      </div>
        
                      <div className='flex mt-3 space-x-2'>
                        <button
                          type='button'
                          onClick={onCloseModal}
                          className="bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                        >Cancel
                        </button>
        
                        <button
                          type='button'
                          onClick={logOut}
                          className="bg-[#FF212E] text-[#fff] mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                        >Log Out
                        </button>
        
                      </div>
                    </div>
                  </div>
                </Modal>
      </div>
      {/* End Navbar */}
    </>
  );
}

Navbar.defaultProps = {
  title: " ",
};
