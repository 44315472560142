/*eslint-disable*/
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AuthApis } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/loginSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdDashboard } from "react-icons/md";
import { TbReceipt } from "react-icons/tb";
import { AiFillDollarCircle } from "react-icons/ai";
import { IoSchoolOutline } from "react-icons/io5";
import { RiSwapLine } from "react-icons/ri";
//  import { RiTokenSwapLine } from "react-icons/ri";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { GrMoney } from "react-icons/gr";
import { TbMoneybag } from "react-icons/tb";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CiShop } from "react-icons/ci";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Screens/User/index.css"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CiWallet } from "react-icons/ci";

// import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
// import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);
  // && userLoginData.data?.user_data?.isVerified == 'true'

  // console?.log(userLoginData.token)
  React.useEffect(() => {
    if (!userLoginData.token) {
      removeAuth()
    }
  }, []);

  React.useEffect(() => {
    if (userLoginData.data?.user_data?.status == 'pending') {
      navigate("/kyc")
    } else if (userLoginData.data?.user_data?.status == 'inReview') {
      navigate("/kyc")
    }
  }, []);

  const removeAuth = () => {
    dispatch(login([]))
    navigate("/sign-in");
  };

  // React.useEffect(() => {
  //   (userLoginData.token && userLoginData?.data?.role == 'user') ?
  //     ''
  //     :
  //     navigate('/sign-in');
  // }, []);

  const logOut = () => {
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/sign-in');

        }
      }
    ).catch(function (error) {
      // handle error
      dispatch(login([]))
      navigate('/sign-in');
      console.log(error.response.data);
      // console.log("new error");
    }).finally(function () {
      // navigate('/sign-in');
      dispatch(login([]))
      navigate('/sign-in');
    }

    )

  };

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [eduSilver, setEduSilver] = useState(false);
  const [eduGold, setEduGold] = useState(false);
  const [eduPremium, setEduPremium] = useState(false);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  // DrawerToggle
  const drawerToggle = (section: string) => {
    // props.open();

    section === "silver" ? setEduSilver(true) : setEduSilver(false);
    section === "gold" ? setEduGold((prev: any) => !prev) : setEduGold(false);
    section === "premium" ? setEduPremium((prev: any) => !prev) : setEduPremium(false);

  };

  return (
    <>
      <nav className="bg-[white] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl   flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 pl-6 pr-2">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex  justify-between w-full">
          {/* Toggler */}
          <div className="flex justify-between">
            <div className="mr-56">
              <button
                className="cursor-pointer text-black opacity-50 md:hidden  py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow("bg-[#05401C] m-2 py-3 px-6")}
              >
                <b className="fas fa-bars"> ≡ </b>
              </button>
            </div>
            <div className=" flex justify-end">
              <Link
                className="md:hidden block "
                to="/"
              >
                <img src="/images/Dashbord/logo.png" alt="logo" className="w-[100px]" />

              </Link>
            </div>

          </div>


          {/* Brand */}
          {/* <Link
            className=" md:block text-center md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img src="/images/Dashboard/logo2.svg" alt="hero" />
          </Link> */}
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              {/* <NotificationDropdown /> */}
            </li>
            <li className="inline-block relative">
              {/* <UserDropdown /> */}
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "bg-[white] md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header w-[78px] */}
            <div className=" md:min-w-full md:hidden block   mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    <img src="/images/Dashbord/logo.png" alt="logo" className="w-[100px]" />
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <b className="fas fa-times"> X </b>
                  </button>
                </div>
              </div>
            </div>


            <div className="lg:flex md:flex hidden justify-center">
              <NavLink to="/" >
                <img src="/images/Dashbord/logo.png" alt="logo" className="max-w-[120px]" />
              </NavLink>


            </div>


            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mt-8">
              <li className="items-center mb-2">
                <NavLink to="/new-dashboard" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <MdDashboard className="" size={24} />
                  <span className="mt-0.5"> Dashbord</span>
                </NavLink>
              </li>

              <li className="items-center mb-2">
                <NavLink to="/payments" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <IoSchoolOutline className="" size={24} />
                  <span className="mt-0.5"> Pay Tuition Fees</span>

                </NavLink>
              </li>

              <li className="items-center mb-2">
                <div
                  onClick={() => drawerToggle("silver")}
                  // style={({ isActive }) =>
                  //   isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  // }
                  className={`${eduSilver ? "text-[#ffffff] bg-[#00B07B]" : ""
                    } flex items-center mx-2 py-[10px] px-[15px] justify-between rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]`}
                // className="flex items-center mx-2 py-[10px] px-[15px] justify-between rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                >
                  <h2
                    className="flex gap-2 "
                  >
                    <TbMoneybag className="" size={24} />
                    <span className="mt-0.5"> Loans</span>
                  </h2>
                  <div>{!eduSilver ? <IoIosArrowDown /> : <IoIosArrowUp className="w-5 h-5" />}</div>

                </div>

                <div className={`${!eduSilver && "hidden"} grid ml-8`} >
                  <NavLink to="/loans" className=" flex mx-2 pt-[10px] pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] "
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    {/* <TbMoneybag className="" size={24} /> */}
                    <span className="mt-0.5 text-center">EduSilver Plan</span>

                  </NavLink>
                  {/* <NavLink to="/loans-gold-plan" className=" flex mx-2 pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093]"
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    <span className="mt-0.5 text-center">EduGold Plan</span>

                </NavLink> */}
                  <NavLink to="/loans-gold-plan" className=" flex mx-2 pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] "
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    {/* <TbMoneybag className="" size={24} /> */}
                    <span className="mt-0.5 text-center">EduGold Plan</span>

                  </NavLink>
                  <NavLink to="/loans-premium-plan" className=" flex mx-2 pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] "
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    {/* <TbMoneybag className="" size={24} /> */}
                    <span className="mt-0.5 text-center">EduPremium Plan</span>

                  </NavLink>


                </div>
                {/* <NavLink to="/loans" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    style={({ isActive }) =>
                      isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    }
                  >
                    <TbMoneybag className="" size={24} />
                    <span className="mt-0.5"> Loans</span>

                </NavLink> */}
              </li> 
              {/* <li className="items-center mb-2">
                <NavLink to="/topup" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <RiSwapLine className="" size={24} />
                  <span className="mt-0.5">Top Up </span>

                </NavLink>
              </li> */}
              <li className="items-center mb-2">
                <NavLink to="/swap-coming-soon" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <RiSwapLine className="" size={24} />
                  <span className="mt-0.5">Top Up </span>

                </NavLink>
              </li> 
              <li className="items-center mb-2">
                <NavLink to="/savings" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <CiWallet className="" size={24} />
                  <span className="mt-0.5">Savings</span>

                </NavLink>
              </li>
              {/* <li className="items-center ">
                <NavLink to="/proof-of-funds" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <AiFillDollarCircle className="" size={24} />
                  <span className="mt-0.5"> Proof of Funds</span>

                  </NavLink>
                </li>
                {/* <li className="items-center ">
                   <NavLink to="/proof-of-funds" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                     style={({ isActive }) =>
                       isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                     }
                   >
                     <AiFillDollarCircle className="" size={24} />
                     <span className="mt-0.5"> Proof of Funds</span>
   
                   </NavLink>
                 </li> */}
              <hr className=" w-full text-[#D9D9D9]" />



              <hr className="mt-10 w-full text-[#D9D9D9]" />
              <li className="items-center mb-2 mt-2">
                <NavLink to="/afripay-shop" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <CiShop className="" size={24} />
                  <span className="mt-0.5"> AfriPay Shop</span>

                </NavLink>
              </li>

              <li className="items-center">
                <NavLink to="/transactions" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <TbReceipt className="" size={24} />
                  <span className="mt-0.5"> Transactions</span>

                </NavLink>
              </li>
              <hr className=" w-full text-[#D9D9D9]" />
              <hr className="mt-20 w-full text-[#D9D9D9]" />

              <div
                onClick={() => navigate("/contact-us")}
                className="mt-3 hidden lg:block">

                <img src="/images/Dashbord/contact-card.svg" alt="logo" className="" />
              </div>


              <li className="flex justify-start gap-2 pt-4 px-5 pb-3" style={{ cursor: 'pointer' }} 
              // onClick={logOut}
              onClick={onOpenModal}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#db1e36" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4" /></svg>
                <span className=" text-[#DB1E36] text-[14px] font-medium mt-2">Log out</span>
              </li>
            </ul>


          </div>
        </div>

        <Modal open={open} onClose={onCloseModal} center styles={{
          modal: {
            borderRadius: '15px', // Add border-radius here
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
          },
        }}>
          <div className='md:max-w-md  body-font font-poppins'>
            <div className="flex flex-col mt-2">
              <div className="flex justify-center">
                <svg width="70" height="70" viewBox="0 0 91 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3409 91.9999C7.49716 91.9999 5.0636 90.9999 3.04023 88.9997C1.01686 86.9996 0.00344697 84.5922 0 81.7777V10.2222C0 7.41111 1.01341 5.00548 3.04023 3.00533C5.06705 1.00518 7.50061 0.0034074 10.3409 0H41.3636C42.8286 0 44.0574 0.490667 45.0502 1.472C46.0429 2.45333 46.5375 3.66637 46.5341 5.11111C46.5306 6.55585 46.0343 7.77059 45.045 8.75533C44.0557 9.74007 42.8286 10.229 41.3636 10.2222H10.3409V81.7777H41.3636C42.8286 81.7777 44.0574 82.2684 45.0502 83.2497C46.0429 84.231 46.5375 85.4441 46.5341 86.8888C46.5306 88.3336 46.0343 89.5483 45.045 90.533C44.0557 91.5178 42.8286 92.0067 41.3636 91.9999H10.3409ZM73.2912 51.1111H36.1932C34.7282 51.1111 33.5011 50.6204 32.5118 49.6391C31.5225 48.6577 31.0262 47.4447 31.0227 46C31.0193 44.5552 31.5156 43.3422 32.5118 42.3609C33.508 41.3795 34.7351 40.8889 36.1932 40.8889H73.2912L63.5966 31.3055C62.6487 30.3685 62.1747 29.2185 62.1747 27.8555C62.1747 26.4926 62.6487 25.3 63.5966 24.2778C64.5445 23.2555 65.7509 22.7223 67.2159 22.678C68.6809 22.6337 69.9304 23.1244 70.9645 24.15L89.4489 42.4222C90.4829 43.4444 91 44.637 91 46C91 47.3629 90.4829 48.5555 89.4489 49.5777L70.9645 67.8499C69.9304 68.8722 68.7033 69.3628 67.2831 69.3219C65.863 69.2811 64.6341 68.7478 63.5966 67.7222C62.6487 66.7 62.1971 65.4869 62.2419 64.0831C62.2867 62.6792 62.7814 61.5071 63.7258 60.5666L73.2912 51.1111Z" fill="#FF212E" />
                </svg>
              </div>

              <div className="mt-4">
                <div>
                  <h5 className="text-[15px] text-[#979797]"> Are you sure you want to log out ?</h5>
                </div>
              </div>

              <div className='flex mt-3 space-x-2'>
                <button
                  type='button'
                  onClick={onCloseModal}
                  className="bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                >Cancel
                </button>

                <button
                  type='button'
                  onClick={logOut}
                  className="bg-[#FF212E] text-[#fff] mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                >Log Out
                </button>

              </div>
            </div>
          </div>
        </Modal>

      </nav>
    </>
  );
}
