import React, { useState } from 'react'
import "react-responsive-modal/styles.css";
import Navbar from '../Navbar'


function BookFlightCom() {
    // Iframe ID
    const IframeId = 'travelstartIframe-3ddec78d-91ef-43f4-92c9-01fbe09aadeb';
    return (
        <div>
             <Navbar />
           <div className=" px-8 lg:px-16  pb-6  lg:gap-8 xl:gap-0 lg:pt-20 lg:grid-cols-12">
           <iframe
        src="https://www.travelstart.com.ng"
        sandbox="allow-scripts allow-same-origin"
        id={IframeId}
        width="100%"
        // height="400"
        className='pb-10 '
        >
      </iframe>
           </div>
        </div>
    )
}

export default BookFlightCom;