import React, { Fragment, useState } from "react";
import Navbar from "./Navbar";
// import {
//   Accordion,
//   AccordionHeader,
//   AccordionBody,
// } from "@material-tailwind/react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

// import 'react-accessible-accordion/dist/fancy-example.css';
import Footer from "./Footer";
import { IoIosArrowDown, IoIosArrowUp, IoIosSearch } from "react-icons/io";

function Icon({ id, open }: { id: any; open: any }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  // const [open, setOpen] = React.useState(1);

  // const handleOpen = (value : any) => setOpen(open === value ? 0 : value);

  // const [open, setOpen] = React.useState(1);
  // const handleOpen = (value: any) =>setOpen(open === value ? 0 : value);
  return (
    <>
      <Navbar />
      <section className=" pb-8 ">
        <div className="bg-[#F9F9F9] pt-[120px] flex flex-col justify-center items-center px-4 pb-6">
          <h1 className="text-[30px]  font-bold  tracking-normal  md:text-[64px] xl:text-[64px]">
            FAQ
          </h1>
          <p className="text-[12px] md:text-[16px] text-[#A6A6A6]">
            Need answers ? Find them here...
          </p>
        </div>
        {/* <!--  Question and answer section --> */}
        <div className="bg-white pt-[40px] pb-20">
          <div className="flex  flex-col px-6 lg:px-[200px]">
            <div className="flex-1 basis-1/5">
              <p className="lg:text-[15px] text-[11px] text-[#A6A6A6] font-medium">
                Couldn,t find what you were looking for?
                <br />
                write to us at{" "}
                <span className="text-[#209D5A]">hello@goafripay.com</span>
              </p>
            </div>
            <div className="flex-1 basis-2/5 lg:mt-0 mt-8">
              <>
                
                {/* <div className="flex justify-center mt-7">
                  <div className="relative flex w-[800px] mb-7 overflow-hidden">
                    <div className="absolute bg-[#00B07B] rounded-r-[10px] inset-y-0 right-0 overflow-hidden flex items-center px-3 pointer-events-none">
                     
                    </div>
                    <input
                      type="text"
                      onClick={() => paginator('')}
                      onChange={e => setSearchText(e.target.value)}
                      placeholder="Type Your Question"
                      id="simple-search"
                      className=" border border-gray-300 text-gray-500 text-sm py-[15px] rounded-[10px] block w-full pl-4   "
                      required
                    />
                  </div>
                </div> */}
                <Accordion allowZeroExpanded allowMultipleExpanded style={{marginTop:"30px"}}>
                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>What is AfriPay?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay is an innovative platform simplifying
                        international tuition payments, securing educational
                        loans, and offering a digital wallet feature for
                        seamless financial transactions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              How does AfriPay simplify tuition payments?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay streamlines the tuition payment process,
                        providing a user-friendly interface and multiple payment
                        options for efficient and secure transactions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              What security measures does AfriPay have in place?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay employs cutting-edge encryption technologies to
                        ensure the security and confidentiality of user data,
                        providing a safe environment for financial transactions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  {/* <AccordionItem className='border border-[#D9D9D9] px-3 rounded-[10px] mb-3'>
                  <AccordionItemHeading>
                    <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                      <span>Can AfriPay help with proof of funds for students?</span>
                      <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                      </svg>
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                    <p>
                    Yes, AfriPay facilitates proof of funds, making it easier for students to showcase financial readiness when applying to study abroad.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem> */}
                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              What is the digital wallet feature in AfriPay?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        The digital wallet feature allows users to conveniently
                        manage and access funds, enabling cashless transactions,
                        budgeting, and automated savings.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              How does AfriPay support educational loans?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay assists users in securing educational loans,
                        streamlining the application process, and providing
                        transparent terms for quick approvals.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              Are there any hidden fees in AfriPay's services?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        No, AfriPay maintains a transparent fee structure,
                        ensuring users are aware of any charges upfront with no
                        hidden fees during transactions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              What types of payments are accepted on AfriPay?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay supports various payment methods, including
                        credit/debit cards, bank transfers, and regional payment
                        options, offering flexibility to users.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              Is AfriPay compliant with financial regulations?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        Yes, AfriPay adheres to international and local
                        financial regulations, ensuring compliance and
                        trustworthiness in all transactions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>When should I apply for funding?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        You can request AfriPay funding if you’ve applied to,
                        been accepted at, or are currently enrolled in an
                        eligible school. But we can’t finalize your application
                        until you’ve been accepted to your program. You can
                        apply for funding up to 12 months before your program
                        starts. Our loans don’t cover students with more than
                        two years remaining in their program. If this is you,
                        check out our scholarships or contact your school’s
                        financial aid office.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>How much can I borrow?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay offers loans between US$2,001 and US$50,000 per
                        academic period, with a lifetime limit of US$100,000.
                        For example, a student might borrow US$50,000 in their
                        fall semester and another US$50,000 in their spring
                        semester.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>What are AfriPay’s interest rates?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        Our interest rates are fixed, which means they won't
                        change as market interest rates change. Our current
                        rates are between 7.99% (8.89% APR¹) and 13.99% (14.98%
                        APR¹). Although we don’t negotiate interest rates, we do
                        offer three interest rate discounts to reduce your rate
                        by up to 1.50%. You’ll get a 0.50% discount when you
                        enroll in autopay; another 0.50% discount when you make
                        six consecutive, on-time payments using autopay; and a
                        final 0.50% deduction when you start a full-time,
                        salaried job after graduation!
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              What else can I expect from an AfriPay loan?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      These are some top highlights when funding your education
                      with us:
                      <ol className="space-y-1 list-disc list-inside">
                        <li>
                          No cosigner, collateral, or credit history required
                        </li>
                        <li>
                          Fixed interest rates with up to 1.50% in discounts
                          (details above)
                        </li>
                        <li>
                          Build your U.S. credit history with on-time payments
                        </li>
                        <li>
                          No fees to apply, free visa and career support to
                          customers
                        </li>
                        <li>100% digital application process</li>
                        <li>All loans are in USD</li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              Do I need a credit history or cosigner to apply?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay doesn’t require a credit history or cosigner to
                        apply because we make loan decisions based on your
                        future potential. If you do have a credit history, we’ll
                        review it looking for things like late payments and
                        collections.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>What are the repayment terms?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        <b>
                          {" "}
                          While you’re in school (and six months after you
                          graduate),
                        </b>{" "}
                        you’re only required to make interest payments each
                        month. That way you can focus on your studies - while
                        building credit and getting used to making on-time
                        payments!
                        <br />
                        <b> Six months after you graduate,</b> your 10-year
                        repayment term begins. At this point, you’ll start
                        making full principal and interest payments each month
                        until your entire balance is paid off.
                        <br />
                        <br />
                        There’s no prepayment penalty if you pay off part or all
                        of your loan ahead of time! Just contact your servicer
                        to request the correct payoff amount.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>Does AfriPay offer visa support?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        Yes! AfriPay offers free support letters to all our
                        approved students to assist their visa process. For
                        students studying in the U.S., our support letters will
                        help you receive your I-20 from your school and the visa
                        application afterwards. For those studying in Canada,
                        our support letter can help prove that you have enough
                        funds to support your education to both your school and
                        Canadian government.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              Which U.S. and Canadian schools are eligible?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        AfriPay supports 400+ schools in the U.S. and Canada!
                        Check out the full list on our website, or check your
                        eligibility to see if your school’s eligible. Schools in
                        other countries are not currently on our school list,
                        and you’ll need to reside in the country of your school
                        while completing your program.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>
                              What documents and personal information do I need
                              to apply?
                            </span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        You’ll need your passport or other national ID to submit
                        your application. You’ll also need to submit information
                        about your program and how you’ll fund it in addition to
                        an AfriPay loan. Once you submit your application,
                        you’ll upload additional documents to your AfriPay
                        account. We’re happy to help if you have any issues
                        uploading your documents.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>Why was my AfriPay application denied?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <p>
                        We’re sorry we couldn’t approve your application. Please
                        know that AfriPay reviews each application carefully
                        before making a loan decision. The specific reason for
                        decline would have been communicated to you through your
                        registered email with the subject line “We are sorry,
                        your loan application was not approved”. If you need
                        additional information, please reach out to your
                        dedicated relationship manager. To start a new loan
                        application, you can easily begin the process online.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>How do I Know if I’m Eligible?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <ol className="space-y-1 list-disc list-inside">
                        <li>
                          You want to study at a school & programme we support
                        </li>
                        <li>You’re from a country or state we support</li>
                        <li>
                          You plan to study abroad (UK residents excepted)
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>How do I Start my application?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <ol className="space-y-1 list-disc list-inside">
                        <li>Receive an instant provisional offer</li>
                        <li>
                          You can complete the entire process online & get a
                          conditional offer within 5 business days!
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>How much can I borrow?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <ol className="space-y-1 list-disc list-inside">
                        <li>
                          {" "}
                          Depending on your programme, you may request up to the
                          full cost of tuition
                        </li>
                        <li>
                          {" "}
                          For US & some other programmes you may also request
                          for living expenses
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>What documents are required?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <ol className="space-y-1 list-disc list-inside">
                        <li> Proof of ID & proof of address</li>
                        <li> Proof of admission</li>
                        <li> Proof of income & savings (if applicable)</li>
                        <li> Proof of savings</li>
                        <li> Credit Report</li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>What is the interest rate?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <ol className="space-y-1 list-disc list-inside">
                        <li>
                          {" "}
                          Our variable rates are comprised of an individual
                          fixed margin rate plus a quarterly averaged US SOFR
                          base rate
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="border border-[#00B07B] overflow-hidden rounded-[10px] mb-3">
                    <AccordionItemHeading>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton
                            className={`py-[10px] mb-3  text-[15px] text-[#777777] flex items-center justify-between ${
                              expanded
                                ? "bg-[#00B07B] text-white pl-6 pr-3"
                                : "px-3"
                            }`}
                          >
                            <span>Can my loan be used as proof of funds?</span>
                            {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="pb-6 px-3 text-[13px] text-[#777777]">
                      <ol className="space-y-1 list-disc list-inside">
                        <li>
                          {" "}
                          Yes! Our loan confirmation letter is valid proof of
                          funds during your visa application
                        </li>
                        <li>
                          {" "}
                          We do not charge any fees for your loan
                          confirmation/sanction letter
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </>
            </div>
            <div className="flex-1 basis-1/5"></div>
          </div>
        </div>
      </section>

      {/* <!--  Question and answer section --> */}

      {/* <-- Footer --> */}
      <Footer />
    </>
  );
}

export default FAQ;
