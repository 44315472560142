import React, { useState, useEffect } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { Oval } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import consultationData from "../../assets/consultation.json";
import markDone from "../../assets/markDone.json";
import Modal from "react-responsive-modal";
import { DashboardApi } from '../../../apis/DashboardApi';
import LoadingSpinner from "../../UI/LoadingSpinner";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TbCopy } from "react-icons/tb";
import { MdOutlineMail } from "react-icons/md";
import { TbBrandWhatsapp } from "react-icons/tb";
import { PaymentApis } from '../../../apis/paymentApis';

function EduPreStudyLoan() {
    const navigate = useNavigate();
    const [loader, setLoader] = React.useState<any>(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    const [currency, setCurrency] = useState<'local' | 'foreign'>('local');
    const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes in seconds

    useEffect(() => {
        if (timeLeft <= 0) return;

        const timer = setInterval(() => {
            setTimeLeft(prev => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // const copyToClipboard = (text: string) => {
    //     navigator.clipboard.writeText(text);
    //   };
    const [copiedField, setCopiedField] = useState<string | null>(null);
    const copyToClipboard = (text: string, field: string) => {
        navigator.clipboard.writeText(text);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 2000);
    };

    const [openPayment, setOpenPayment] = useState(false);
    const onOpenPaymentModal = () => {

        setOpenPayment(true);
    };
    const onClosePaymentModal = () => {
        setOpenPayment(false);
        onOpenPaymentProofModal();
    };
    const [openPaymentProof, setOpenPaymentProof] = useState(false);
    const onOpenPaymentProofModal = () => {

        setOpenPaymentProof(true);
    };
    const onClosePaymentProofModal = () => {
        setOpenPaymentProof(false);
        setUserdata({
            full_name: "",
            phone_no: "",
            email: "",
            country_of_sch: "",
            name_of_sch: "",
            course: "",
            resumption_date: "",
        });
        window.location.reload()
    };

    const [openConsultation, setOpenConsultation] = useState(false);
    const onOpenConsultationModal = () => {
        // e.preventDefault();
        setOpenConsultation(true);
    };
    const onCloseConsultationModal = () => {
        setOpenConsultation(false);
        window.location.reload()
    };

    const [openDone, setOpenDone] = useState(false);
    const onOpenDoneModal = () => {
        // e.preventDefault();
        setOpenDone(true);
    };
    const onCloseDoneModal = () => setOpenDone(false);

    const [buttonText, setButtonText] = useState("Submit");

    const [userData, setUserdata] = useState({
        full_name: "",
        phone_no: "",
        email: "",
        country_of_sch: "",
        name_of_sch: "",
        course: "",
        resumption_date: "",

    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };


    const handleSubmit = React.useCallback(

        (e: any) => {
            e.preventDefault();

            setLoader(true)

            const userDatas = new FormData();
            userDatas.append('name', userData?.full_name);
            userDatas.append('phone_no', userData?.phone_no);
            userDatas.append('email', userData?.email);
            userDatas.append('country_of_sch', userData?.country_of_sch);
            userDatas.append('name_of_sch', userData?.name_of_sch);
            userDatas.append('course', userData?.course);
            userDatas.append('resumption_date', userData?.resumption_date);

            DashboardApi.applyStudyLoan(userDatas).then(
                (response: any) => {
                    if (response?.data) {
                        setLoader(false);
                        setButtonText("Submitted");
                        toast.success('Your form is successful');

                        // onOpenConsultationModal();
                        onOpenPaymentModal()
                        // setUserdata({
                        //     full_name: "",
                        //     phone_no: "",
                        //     email: "",
                        //     country_of_sch: "",
                        //     name_of_sch: "",
                        //     course: "",
                        //     resumption_date: "",
                        // })



                    }
                }
            ).catch((err) => {
                if (err?.response?.status === 422) {
                    const responseMessage = err?.response?.data?.message || ""; // Extract the error message from the response
                    if (responseMessage === "The email has already been taken.") {
                        toast.error("This email has already been used");
                    } else {
                        toast.error("Form not rightly filled. Kindly cross-check.");
                    }
                    setLoader(false);
                } else {
                    toast.error("Some error occurred. Please try again.");
                    setLoader(false);
                }
            })
                .finally(() => {

                });
        },
        [userData]
    );

    const [rate, setRate] = useState<number>(0);
    const [convertedValue, setConvertedValue] = useState<number>(0);

    useEffect(() => {
        PaymentApis.getNGNRate().then((response: any) => {
            if (response?.data?.result) {
                const baseRate = parseFloat(response.data.result);
                if (!isNaN(baseRate)) {
                    const currentRate = baseRate + 59.29;
                    setRate(currentRate);
                    setConvertedValue(currentRate * 250);
                }
            }
        }).catch(error => {
            console.error('Error fetching rate:', error);
        });
    }, []);


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen'>
                    <HeaderTitle title="Loan Process package " />
                    <div className='flex space-x-2'>
                        <div className=' place-content-center mt-2 cursor-pointer' onClick={() => { navigate(-1) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#6e7093" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" /></svg>
                        </div>
                        <div className='mt-2'>
                            <h3 className='text-[14px] font-medium'>Kindly fill the information below to help you  with the loan package</h3>
                        </div>
                    </div>


                    <div className='bg-white rounded-[10px] lg:px-[20px] lg:py-[20px] px-[20px] pt-[20px] pb-[30px] mt-[14px]'>
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  ">
                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                    <div className="flex-auto  ">
                                        <div className="flex flex-wrap ">

                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="John William"
                                                        name="full_name"
                                                        required
                                                        value={userData?.full_name || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="080****"
                                                        name="phone_no"
                                                        required
                                                        value={userData?.phone_no || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="johnwilliam@gmail.com"
                                                        name="email"
                                                        required
                                                        value={userData?.email || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Country of school you received admission in
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="country_of_sch"
                                                        required
                                                        value={userData?.country_of_sch || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Name of the school you receive admission in
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="name_of_sch"
                                                        required
                                                        value={userData?.name_of_sch || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Course you are admitted for
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="course"
                                                        required
                                                        value={userData?.course || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 ">
                                                    <label className="block mb-2 text-sm font-semibold text-[#000000]">
                                                        Preferred resumption
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="bg-white border border-[#D0D5DD] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#C2C3C3] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        name="resumption_date"
                                                        required
                                                        value={userData?.resumption_date || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-start  ">
                                <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}

                                    className="w-5 h-5 accent-green-600 bg-[#D9D9D9] border-green-600 rounded"
                                />
                                <label
                                    htmlFor="default-checkbox"
                                    className="ml-2 text-[15px]  text-black "
                                >
                                    I agree to use the loan for tuition fee
                                </label>
                            </div>

                            <div className='mt-5'>
                                <button
                                    type='submit'
                                    disabled={!isChecked}
                                    //  onClick={onOpenConsultationModal}
                                    className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] ${isChecked ? "bg-[#00B07B]" : "bg-gray-600 cursor-not-allowed"
                                        }`}>
                                    {loader ? (
                                        <>

                                            <span className="mr-2">{buttonText}</span>
                                            <LoadingSpinner /> {/* Show loader */}
                                        </>
                                    ) : (
                                        buttonText
                                    )}
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>

            <Modal open={openPayment} onClose={onClosePaymentModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>

                <div className=" flex justify-start  mt-1">
                    <h4 className="tex-[#000000] text-[16px]  font-[500]">
                        Payment
                    </h4>

                </div>
                <div className="md:w-[500px] body-font font-poppins">

                    {/* Currency Toggle */}
                    <div className="flex justify-center gap-8 mb-5 mt-4">
                        <button
                            className={`pb-1 text-[12px] ${currency === 'local'
                                ? 'text-[#00B07B] border-b-2 w-[200px] border-[#00B07B]'
                                : 'text-[#C1BDBD] '
                                }`}
                            onClick={() => setCurrency('local')}
                        >
                            Local Currency For NGN Only
                        </button>
                        <button
                            className={`pb-2 text-[12px] ${currency === 'foreign'
                                ? 'text-[#00B07B] border-b-2 w-[200px] border-[#00B07B]'
                                : 'text-[#C1BDBD] '
                                }`}
                            onClick={() => setCurrency('foreign')}
                        >
                            Foreign Currency For Non NGN
                        </button>
                    </div>

                    {/* Payment Amount */}
                    <div className="bg-[#F7F7F7] rounded-[8px] pt-3 pb-1 text-center mb-5">
                        <p className="text-[14px] text-[#5A5A5A]">Payment</p>
                        <p className="text-[28px] font-semibold text-[#00B07B]">$250</p>
                    </div>

                    {/* local page */}
                    <div className={currency == 'local' ? "block " : "hidden"}>
                        <div className=" mt-[20px] mb-8">
                            <div className='border border-[#D6D9DC] rounded-[10px] px-2 py-2'>

                                <div className='flex justify-between'>
                                    <h3 className='font-semibold text-[20px] text-[000]'>Summary</h3>
                                    <span className="text-sm text-center text-[#B00000] border border-[#B00000] rounded-[8px] pt-1 px-4">
                                        Expire in {formatTime(timeLeft)}

                                    </span>
                                </div>

                                {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Fee</h4>
                                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $22</h3>
                                </div> */}

                                <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Rate</h4>
                                    {/* <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'> $1= ₦{rate?.toFixed(2)} </h3> */}
                                  {/* <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>₦{(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((rate) ? (rate) : 0.0))?.substring(4)}</h3>   */}
                                  {/* <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'> $1= ₦{rate} </h3> */}
                                  {/* <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>
    ₦{new Intl.NumberFormat('en-US').format(rate)}
</h3> */}
<h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>
   ₦{rate.toLocaleString('en-US', {
       minimumFractionDigits: 2,
       maximumFractionDigits: 2
   })}
</h3>
                                </div>

                                <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>You will transfer</h4>
                                    {/* <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>₦{convertedValue?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3> */}
                                    <h3 className='text-[#525252] lg:text-[15px] text-[12px] font-[500]'>
    ₦{Math.ceil(convertedValue).toLocaleString("en-US", { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
    })}
</h3>
                                </div>

                            </div>
                        </div>

                        {/* Bank Details */}
                        <div className="space-y-4 mb-8">
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Name</span>
                                <span className="font-medium text-[14px]">AFRIPRO PLATFORM NIG LTD</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Number</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">1026908037</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('1026908037', 'account')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'account' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Bank Name</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">United Bank of Africa (UBA)</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('United Bank of Africa (UBA)', 'bank')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'bank' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <button onClick={onClosePaymentModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                            I have make payment
                        </button>
                    </div>

                    {/* foreign page */}
                    <div className={currency == 'foreign' ? "block " : "hidden"}>
                        <div className=" mt-[20px] mb-8">
                            <div className='border border-[#D6D9DC] rounded-[10px] px-2 py-2'>

                                <div className='flex justify-between'>
                                    <h3 className='font-semibold text-[20px] text-[000]'>Summary</h3>
                                    {/* <span className="text-sm text-center text-[#B00000] border border-[#B00000] rounded-[8px] pt-1 px-4">
                                        Expire in {formatTime(timeLeft)}

                                    </span> */}
                                </div>

                                {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Fee</h4>
                                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $22</h3>
                                </div> */}

                                {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Rate</h4>
                                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $1= ₦1,585.99 </h3>
                                </div> */}

                                <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>You will transfer</h4>
                                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'>$250 </h3>
                                </div>

                            </div>
                        </div>

                        {/* Bank Details */}
                        <div className="space-y-4 mb-8">
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Name</span>
                                <span className="font-medium text-[14px]">AFRIPRO OY</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Number</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">8312818556</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('8312818556', 'acct')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'acct' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Type</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">Checking</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('Checking', 'type')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'type' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Bank Name</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">WISE</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('WISE', 'bank')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'bank' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Routing Number</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">026073150</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('026073150', 'routing')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'routing' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Swift/BIC</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">CMFGUS33</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('CMFGUS33', 'swift')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'swift' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Bank Address</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">Community Federal Savings Bank, <br />89-16 Jamaica Ave, Woodhaven, <br />NY, 11421, United State</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('Community Federal Savings Bank,89-16 Jamaica Ave, Woodhaven,NY, 11421, United State', 'address')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'address' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Recipient Address</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">Lotankatu 3, Espoo, 02650, Finland</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('Lotankatu 3, Espoo, 02650, Finland', 'reciAddress')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'reciAddress' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button onClick={onClosePaymentModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                            I have make payment
                        </button>
                    </div>

                </div>
            </Modal>

            <Modal open={openPaymentProof} onClose={onClosePaymentProofModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>
                <div className='md:w-[350px] w-[300px]'>
                    <div className=" flex justify-start mt-7">
                        <h4 className="tex-[#000000] md:text-[18px] text-[14px] font-bold">
                            Proof of Payment
                        </h4>

                    </div>
                    <div className='flex  mt-5'>
                        <p className='text-[14px] text-[#898989] '>Kindly send your receipt to any of the following platform to confirm your payment  </p>
                    </div>

                    <div className=' mt-5'>
                        <div className='flex gap-2'>
                            <MdOutlineMail size={20} />
                            <a href="mailto:customersupport@goafripay.com" className=' underline underline-offset-2 text-[14px]'>customersupport@goafripay.com</a>
                        </div>
                        <div className='flex gap-2 mt-3'>
                            <TbBrandWhatsapp size={20} />
                            <NavLink to="https://api.whatsapp.com/send?phone=2348131470992" target='_blank'>
                                <span className=' underline underline-offset-2 text-[14px]'>+234 813 147 0992</span>
                            </NavLink>
                        </div>
                    </div>
                    <div>

                        <div className='mt-5 flex justify-center'>
                            <button onClick={onClosePaymentProofModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                                Okay
                            </button>

                        </div>
                    </div>
                </div>

            </Modal>

            <Modal open={openConsultation} onClose={onCloseConsultationModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>
                <div className='md:w-[350px] w-[300px]'>
                    <div className=" flex justify-center">
                        <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                            Register for free consultation
                        </h4>

                    </div>
                    <div className='flex justify-center mt-4'>
                        <span className='text-[12px] text-[#977E0C] bg-[#FFF6DA] rounded-[8px] py-[10px] px-[18px]'>Note : It’s Important you register for Consultation</span>
                    </div>
                    <div>
                        <div className='flex justify-center mt-4'>

                            <div>
                                <Lottie animationData={consultationData} loop={true} />
                            </div>
                        </div>
                        <div className='mt-3 flex justify-center'>
                            <NavLink to={"https://calendar.app.google/cEdf5effNYckaFK39"} target='_blank'>
                                <button

                                    // onClick={onCloseConsultationModal}
                                    className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Book Consultation</button>
                            </NavLink>

                        </div>
                    </div>
                </div>

            </Modal>

            <Modal open={openDone} onClose={onCloseDoneModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>
                <div className='md:w-[350px] w-[300px]'>

                    <div className='flex justify-center mt-4'>
                        <div>
                            <Lottie animationData={markDone} loop={true} />
                        </div>
                    </div>

                    <div className=" flex justify-center mt-5">
                        <h4 className="tex-[#000000] md:text-[18px] text-[20px] text-center font-bold">
                            Study Loan Application Submitted
                        </h4>

                    </div>
                    <div>
                        <p className='text-center'>You application for Loan processing have been submitted successfully </p>
                    </div>

                    <div className='flex justify-center mt-5'>
                        <button
                            onClick={onCloseDoneModal}
                            className={` bg-[#00B07B] text-white rounded-[8px] px-[30px] py-[10px] `}>Done</button>
                    </div>

                </div>

            </Modal>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default EduPreStudyLoan