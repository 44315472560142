import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class PaymentLiveApis extends AxiosGlobal{
    

    
    creditUserWallet(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditUserInNaira(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet-in-naira`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditUserInGhs(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet-in-ghs`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditUserInkes(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet-in-kes`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditUserInMal(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet-in-mal`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditUserInUgh(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet-in-ugh`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditUserInSa(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/flutterwave/credit-user-wallet-in-sa`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 
    
    verifyWalletTopup(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/flutterwave/verify_user_wallet?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    verifyWalletTopupInNaira(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/flutterwave/verify_wallet_payment_in_naira?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    getTransactionHistory(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/flutterwave/get-transaction-history?page=${pageNo}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getTransactionHistoryById(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/flutterwave/get-transaction-history/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    creditHistoryPayment(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/make-credit-history-payment`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditHistoryPremiumPayment(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/make-credit-history-premium-payment`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    creditHistoryPremiumLoanPayment(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/make-credit-history-premium-loan-payment`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    verifyCreditHistoryPayment(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/verify-credit-history-payment?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    verifyEduPremiumPayment(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/verify-edu-premium-payment?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    getNGNRate(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-ngn-rate`,{
            headers: { "Content-Type": "application/json","Accept":"application/json" },
          });
    }

}